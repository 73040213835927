<template>
    <Teleport to="body">
        <div class="loadingBase" v-if="isAvailableBrowser">
            <div class="loading">
                <!-- style="--index: *;" でインデックス番号を振る -->
                <span class="loading-item" style="--index: 0;"></span>
                <span class="loading-item" style="--index: 1;"></span>
                <span class="loading-item" style="--index: 2;"></span>
                <span class="loading-item" style="--index: 3;"></span>
                <span class="loading-item" style="--index: 4;"></span>
                <span class="loading-item" style="--index: 5;"></span>
                <span class="loading-item" style="--index: 6;"></span>
                <span class="loading-item" style="--index: 7;"></span>
                <p class="loading-text"><span style="--index: 0;">N</span><span style="--index: 1;">o</span><span style="--index: 2;">w</span><span style="--index: 3;"> </span><span style="--index: 4;">L</span><span style="--index: 5;">o</span><span style="--index: 6;">a</span><span style="--index: 7;">d</span><span style="--index: 8;">i</span><span style="--index: 9;">n</span><span style="--index: 10;">g</span><span style="--index: 11;">.</span><span style="--index: 12;">.</span><span style="--index: 13;">.</span></p>
            </div>
        </div>
        <div class="loadingBase" v-else>
            <img src="@/assets/loading.gif" />
        </div>
    </Teleport>
</template>
<style scoped>
    .loadingBase{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 102;
        top: 0; left: 0; right: 0; bottom: 0;
        background-color: rgba(256, 256, 256, 0.1);
        user-select: none;
    }
    .loading {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 200px;
        background-color: rgba(256, 256, 256, 0.8);
        border-radius: 50%;
    }
    .loading-item{
        position: absolute;
        top: 50%; left: 50%;
        background-color: #9bcbf4;
        opacity: 0.5;
        height: 26px; width: 26px;
        border-radius: 50%;

        --angle: calc(360deg / 8 * var(--index));
        --x: calc(cos(var(--angle)) * 100px);
        --y: calc(sin(var(--angle)) * 100px);

        translate: calc(var(--x) - 50%) calc(var(--y) - 50%);

        --duration: 1.8s;
        --delay: calc(var(--duration) * var(--index) / 8);
        animation: sinAnimate var(--duration) var(--delay) infinite ease-in-out;
    }
    .loading-text{
        text-align: center;
        white-space: nowrap;
        font-weight: bold;
        font-size: large;
    }
    .loading-text span{
        position: relative;
        translate: -50%;
        animation: waveAnimation 1.8s calc(1.8s * var(--index) / 14) linear infinite;
    }
    @keyframes waveAnimation {
        0% {
            top: 0;
        }
        20% {
            top: 10px;
        }
        40% {
            top: 0px;
        }
        100% {
            top: 0;
        }
    }

    @keyframes sinAnimate {
        50% {
            opacity: 1;
            transform: scale(1.6);
        }
    }
</style>
<script>
import { computed } from 'vue';
export default {
    setup(){
        const availableLists = [3, 4, 5];

        const browser = computed(() => {
            const userAgent = window.navigator.userAgent.toLowerCase();

            if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
                return 1
            } else if(userAgent.indexOf('edge') != -1) {
                return 2
            } else if(userAgent.indexOf('chrome') != -1) {
                return 3
            } else if(userAgent.indexOf('safari') != -1) {
                return 4
            } else if(userAgent.indexOf('firefox') != -1) {
                return 5
            } else if(userAgent.indexOf('opera') != -1) {
                return 6
            } else {
                return 0
            }
        })

        const isAvailableBrowser = computed(() => {
            return availableLists.includes(browser.value);
        })

        return {
            isAvailableBrowser
        }
    }
}
</script>