<template>
    <div class="privateContent">
        <div class="">
            <h2 class="w100 textCenter">お気に入りリスト</h2>
            <p>現在登録されている動画が表示されます。</p>
            <p>標準偏差: {{ Math.floor(stdev * 100) / 100 }}, 平均: {{ Math.floor(avg * 100) / 100 }}</p>
            <br />
            <p>URLで動画を追加: <input type="text" placeholder="https://youtu.be/xxxx" v-model="url" /><a class="spBtn"
                    @click="regist">追加</a></p>
            <spSearchAreaVue v-model="setting" @change="load"></spSearchAreaVue>
        </div>
        <PageNation v-model="current" :maxPage="maxPage" :keyObserb="true"></PageNation>
        <table class="normal w100" v-if="favos">
            <thead>
                <tr>
                    <th></th>
                    <th>サムネイル</th>
                    <th>投稿者</th>
                    <th>再生回数</th>
                    <th>偏差値</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="favos.length == 0">
                    <td colspan="6">お気に入りに登録されている曲がありません。</td>
                </tr>
                <spFavoVue v-for="f in favos" :key="f" :video="f" :stdev="stdev"></spFavoVue>
            </tbody>
        </table>
        <PageNation v-model="current" :maxPage="maxPage"></PageNation>
    </div>
</template>
<script>
import PageNation from '@/components/items/pageNation.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import spSearchAreaVue from '../item/spSearchArea.vue';
import spFavoVue from '../row/spFavoRow.vue';

export default {
    setup() {
        const store = useStore();
        const stdev = ref(0); const avg = ref(0); //標準偏差の関連
        const url = ref(""); //手動追加
        const favos = ref([]);  //お気に入り
        const current = ref(1);
        const setting = ref({
            count: "10",
            title: "",
            sort: {
                type: "0",
                order: "1"
            },
            author: "",
            priority: false
        });
        const maxPage = computed(() => {
            return Math.ceil(favos.value.length / setting.value.count);
        })
        const load = async () => {
            let rsp = await store.dispatch('Api/callApi', { url: "private/calcSTDEV" });
            favos.value = await store.dispatch('Api/callApi', {
                url: 'private/favorites', data: {
                    type: setting.value.sort.type,
                    sort: setting.value.sort.order,
                    name: setting.value.title,
                    author: setting.value.author,
                    isPriority: setting.value.priority
                }
            });
            // console.log(favos.value);
            stdev.value = rsp.stdev;
            avg.value = rsp.avg;
        }
        load();
        const regist = async () => {
            //inputChangeStatus
            let uu = url.value;
            url.value = "";
            let rslt = await store.dispatch('Api/callApi', { url: "private/inputChangeStatus", data: { url: uu, kind: "favorite" } });
            if (rslt) {
                await store.dispatch('Common/showModal', { text: "登録しました。" });
                load();
            } else {
                await store.dispatch('Common/showModal', { text: "登録に失敗しました。" });
            }
        }
        const openURL = async (url) => {
            const cnf = await store.dispatch('Common/openConfirm', { header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}` });
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
        }

        const checkMax = (end) => {
            current.value = end
        }

        const favos_ = computed(() => {
            let arry = [];
            let end = current.value * setting.value.count;
            let start = end - setting.value.count;
            // console.log(start, end);
            if (end < current.value) checkMax(end);
            arry = favos.value.slice(start, end);
            return arry;
        })


        return {
            stdev,
            avg,
            url,
            regist,
            setting,
            openURL,
            load,
            favos: favos_,
            current,
            maxPage
        }
    },
    components: {
        spSearchAreaVue,
        spFavoVue,
        PageNation
    }
}
</script>