<template>
    <div class="toggleSwitch">
        <label>
            <div class="baseArea" :class="{valueOn: value}" @click="changed">
                <div class="circle"></div>
            </div>
            <p class="toggleSwitchLabel">{{ label }}</p>
        </label>
    </div>
</template>
<script>
import { ref, watch, computed } from 'vue';

export default {
    props: {
        modelValue: {type: Boolean, default: false},
        label: {type: String, default: ""},
    },
    setup(props, context) {
        const value = ref(false);
        const load = () => {
            value.value = props.modelValue;
        }
        load();

        const baseValue = computed(() => {
            return props.modelValue;
        });
        watch(baseValue, (v) => {
            value.value = v;
        })

        const changed = () => {
            // console.log(!value.value);
            context.emit('update:modelValue', !value.value);
            context.emit('change', !value.value);
        }

        return {
            value,
            changed
        }
    }
}
</script>
<style scoped>
.toggleSwitch{
    display: inline-block;
}
.baseArea{
    position: relative;
    width: 100px;
    height: 50px;
    margin: 1em;
    border-radius: 25px;
    background-color: rgb(207, 207, 207);
    cursor: pointer;
    transition: 0.5s;
}
.baseArea.valueOn {
    background-color: rgb(0, 201, 27);
}

.circle{
    position: absolute;
    background-color: white;
    height: 40px; width: 40px;
    top: 50%; left: 5px;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: 1s;
}

.baseArea.valueOn .circle{
    transform: translate(50px, -50%)
}
.toggleSwitchLabel{
    font-size: large;
    font-weight: bold;
}
</style>