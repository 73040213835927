<template>
    <div class="moonBase" ref="moonBase" :style="{width: `${size}px`, bottom: `${moonPosition.y}%`, left: `calc(${moonPosition.x}% + 15%)`}">
        <div class="moonAge" ref="moonAge">
            <canvas ref="a0"></canvas>
            <canvas ref="a1"></canvas>
            <canvas ref="a2"></canvas>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';

const size = 25;
const pi = Math.PI;
const pi2 = pi * 2;
const topAngle = pi + pi / 2 * 3;
const bottomAngle = pi + pi / 2
const halfSize = size / 2;
const start = [0, topAngle, 0]
const end = [pi2, bottomAngle, pi2];

const props = defineProps({
    age: { type: Number, default: 15 },
    isDayTime: { type: Boolean, default: false},
    rising: {},
    time: {}
})

const moonriseTime = computed(() => {
    return props.rising.moonriseTime
})

const moonsetTime = computed(() => {
    return props.rising.moonsetTime
})

const time = computed(() => {
    return props.time;
})

const moonColor = computed(() => {
    return props.isDayTime ? "#f0f0f0" : "#ffff00"
})
const moonBackColor = computed(() => {
    return props.isDayTime ? "#88a5b7" : "#444444"
})

const moon_age = computed(() => {
    return props.age;
})

const a0 = ref(null);
const a1 = ref(null);
const a2 = ref(null);
const moonBase = ref(null);
const moonAge = ref(null);

const cs = computed(() => {
    return [a0.value, a1.value, a2.value]
});
const ctxs = computed(() => {
    return [
        a0.value.getContext('2d'),
        a1.value.getContext('2d'),
        a2.value.getContext('2d')
    ]
});

const synodic = computed(() => {
    return 29.530588853 + 2.162e-9 * ((new Date().getTime() - 946727935816) / 315576e5);
});

const load = () => {
    const ctx = ctxs.value;
    const c = cs.value;
    moonAge.value.style.height = `${size}px`;
    moonBase.value.style.width = `${size}px`;
    for (let i = 0; i < 3; i++) {
        c[i].style.width = `${size}px`;
        c[i].style.height = `${size}px`;
        c[i].width = size;
        c[i].height = size;
        ctx[i].fillStyle = i === 0 ? moonBackColor.value : moonColor.value;
        ctx[i].arc(halfSize, halfSize, halfSize * .95, start[i], end[i]);
        ctx[i].fill();
    }
    appearance();
}

function appearance() {
    const ctx = ctxs.value;
    const c = cs.value;
    const s = Math.cos(pi2 * moon_age.value / synodic.value);
    const s2 = Math.sin(pi2 * moon_age.value / synodic.value);
    const r = Math.abs(halfSize * s);
    c[1].style.transform = `rotate(${s2 > 0 ? 180 : 0}deg)`;
    ctx[2].clearRect(0, 0, size, size);
    ctx[2].beginPath();
    ctx[2].fillStyle = s > 0 ? moonBackColor.value : moonColor.value;
    ctx[2].arc(halfSize, halfSize, halfSize * .95, 0, pi2);
    ctx[2].fill();
    c[2].style.width = `${r * 2}px`;
    c[2].style.left = `${halfSize - r}px`;
}
const moonPosition = computed(() => {
    const baseTime = moonsetTime.value.unix() - moonriseTime.value.unix();
    const nowTimeUnix = time.value.unix() - moonriseTime.value.unix();

    const percent = nowTimeUnix / baseTime;
    
    const radian = Math.PI * percent;

    const sinPI = Math.round(Math.sin(radian) * 1000) / 1000;

    //整数に下駄履きする
    let x = 1 - percent;
    let y = sinPI;
    
    //座標に補正
    x = x*70;
    y = y*70;

    return {x, y}
});

onMounted(load);
</script>

<style scoped>
.moonBase{
    position: absolute;
    z-index: 0;
}
.moonAge {
    position: relative;
    height: 200px;
}
.moonAge canvas {
    position: absolute;
    top: 0px;
    left: 0px;
}
</style>