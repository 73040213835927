<template>
    <div class="pageNationButtons" ref="paginationBtn">
        <div class="pageButtton prevButton" :class="{ disabled: modelValue == 1 }" @click="up(1)">&lt;&lt;</div>
        <div class="pageButtton prevButton" :class="{ disabled: modelValue == 1 }" @click="prev">&lt;</div>
        <div class="pageButtton numberButton" :class="{ current: modelValue == i }" v-for="i in pageLeng" :key="i"
            @click="up(i)">{{ i }}</div>
        <div class="pageButtton prevButton" :class="{ disabled: modelValue == maxPage }" @click="next">&gt;</div>
        <div class="pageButtton prevButton" :class="{ disabled: modelValue == maxPage }" @click="up(maxPage)">&gt;&gt;</div>
    </div>
</template>
<script>
import { watch, computed, ref, onMounted, onUnmounted } from 'vue';

export default {
    props: {
        maxPage: Number,
        modelValue: Number,
        viewMax: Number,
        keyObserb: { type: Boolean, default: false }
    },
    setup(props, context) {
        const up = (num) => {
            if (isNaN(num)) return;
            if (num < 1) num = 1;
            if (props.maxPage < num) num = props.maxPage
            context.emit('update:modelValue', num);
        }
        const current = computed(() => props.modelValue);
        const maxPage = computed(() => props.maxPage);
        const paginationBtn = ref();


        const fieldWidth = computed(() => {
            //1つ48px
            return paginationBtn.value?.offsetWidth || 0;
        })

        watch(maxPage, (v) => {
            // console.log(`${v}: `, current.value, (v < current.value));
            if (v < current.value) context.emit('update:modelValue', v);
        })

        watch(current, () => {
            // console.log('viewMax', props.viewMax)
            const end = maxPage.value;
            // console.log(end, current.value);
            if (end < current.value) context.emit('update:modelValue', end);
            if (current.value < 1 || !current.value) context.emit('update:modelValue', 1);
        })

        const prev = () => {
            let num = props.modelValue - 1;
            up(num);
        }
        const next = () => {
            let num = props.modelValue + 1;
            up(num);
        }

        const pageLeng = computed(() => {
            let max = props.maxPage;
            let current = props.modelValue;
            let array = null;
            const btnWidth = 48;
            const btnCount = Math.floor(fieldWidth.value / btnWidth) - 5;

            if (btnCount <= 4) {
                //少ない
                // console.log(btnCount, max - btnCount);
                array = [];
                if (current == 1) {
                    for (let i = 1; i <= btnCount + 1; i++) array.push(i);
                } else if (current < max - btnCount + 1) {
                    for (let i = current - 1; i <= current + btnCount - 1; i++) array.push(i);
                } else {
                    for (let i = max - btnCount; i <= max; i++) array.push(i);
                }
            } else if (btnCount < max) {
                array = [1, 2];
                if (current <= 2) {
                    for (let i = 3; i <= btnCount - 2; i++) array.push(i);
                } else if (max - 1 <= current) {
                    for (let i = max - btnCount + 3; i < max - 1; i++) array.push(i);
                } else {
                    let btnCount_ = (btnCount - 4) / 2;
                    const leftBtn = Math.round(btnCount_);
                    const rightBtn = Math.floor(btnCount_);

                    if (current < btnCount - 9) {
                        for (let i = 3; i < btnCount - 1; i++) array.push(i);
                    } else if (current < max - 9) {
                        for (let i = current - leftBtn; i <= current + rightBtn - 1; i++) array.push(i);
                    } else {
                        for (let i = max - (btnCount - 3); i < max - 1; i++) array.push(i);
                    }
                }
                array.push(max - 1, max);
            }
            return array || max;
        })

        const keyObserbWindow = (event) => {
            switch (event.code) {
                default: break;
                case 'ArrowRight':
                    if (current.value < props.maxPage) context.emit('update:modelValue', current.value + 1);
                    break;
                case "ArrowUp":
                    context.emit('update:modelValue', props.maxPage);
                    break;
                case "ArrowLeft":
                    if (1 < current.value) context.emit('update:modelValue', current.value - 1);
                    break;
                case "ArrowDown":
                    context.emit('update:modelValue', 1);
                    break;
            }

        }

        onMounted(() => {
            if (props.keyObserb) {
                window.addEventListener('keydown', keyObserbWindow);
            }
        })

        onUnmounted(() => {
            if (props.keyObserb) {
                window.removeEventListener('keydown', keyObserbWindow);
            }
        })

        return {
            up,
            prev,
            next,
            pageLeng,
            paginationBtn,
            fieldWidth
        };
    }
}
</script>
<style scoped>
.pageNationButtons {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pageButtton {
    padding: 0.5em 0;
    width: 3em;
    text-align: center;
    margin: 1em 0.1em;
    background-color: white;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
}

.pageButtton.current {
    background: linear-gradient(rgb(116, 116, 214), rgb(78, 78, 218));
    color: white;
}

.pageButtton.disabled {
    background-color: gray;
    cursor: not-allowed;
    /* pointer-events: none; */
}</style>