<template>
    <div class="spBase">
        <div class="spBaseHeader">
            <div class="burgerMenu" @click="isMenuShow=!isMenuShow"><img src="@/assets/sp/burger.png" /></div>
            <div class="headerIcon">
                <img src="@/assets/Icon.png" />
                <p class="wf-mplus1p">NiceMusic♪</p>
            </div>
        </div>
        <div class="spBaseBody">
            <spMenuVue v-if="isMenuShow" @close="isMenuShow = false" :MenuLists="menuLists"></spMenuVue>
            <component v-bind:is="component"></component>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import spMenuVue from '../../components/sp/spMenu.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import spTopFieldVue from '@/components/sp/private/spTopField.vue';
import spFavoriteVue from '@/components/sp/private/spFavorite.vue';
import spBlock from '@/components/sp/private/spBlock.vue';
import spMusics from '@/components/sp/private/spMusics.vue';
import SpSetting from '@/components/sp/private/spSetting.vue';

export default {
    setup() {
        const isMenuShow = ref(false);
        const windowWidth = ref(0);
        const router = useRouter();
        const store = useStore();

        const menuLists = {
            1: {id: 1, name: "TOP", component: spTopFieldVue},
            2: {id: 2, name: "お気に入り", component: spFavoriteVue},
            3: {id: 3, name: "ブロック", component: spBlock},
            4: {id: 4, name: "登録曲", component: spMusics},
            8: {id: 8, name: "設定", component: SpSetting}
        }

        const getMenuId = computed(() => {
            return store.getters["Common/getMenu"];
        })

        const load = async ()=>{
            // await store.dispatch('Common/developOnly');
            // calculateWindowWidth();

            if(!menuLists[getMenuId.value]) {
                await store.dispatch('Common/showModal', {text: "スマートフォン版ではこの機能は実装されていません"});
                await store.dispatch('Common/setMenu', 1);
            }
        }
        load();

        const component = computed(() => {
            if(!menuLists[getMenuId.value]) return null;
            return menuLists[getMenuId.value].component;
        })

        const calculateWindowWidth = () => {
            windowWidth.value = window.innerWidth
            // console.log(windowWidth.value);
            // true/false
            if(480 < windowWidth.value) {
                router.push("/u");
            }
        }

        onUnmounted(() => {
            window.removeEventListener('resize', calculateWindowWidth);
        })

        onMounted(() => {
            calculateWindowWidth();
            window.addEventListener('resize', calculateWindowWidth)
        })

        return {
            isMenuShow,
            menuLists,
            component
        }
    },
    components: {
        spMenuVue,
        spTopFieldVue,
        spFavoriteVue
    }
}
</script>
<style scoped>
.spBase{
    width: 100%; height: 100%;
    background-color: #36393f;
    color: white;
}
.spBaseHeader{
    width: 100%; height: 78px;
    border-bottom: #1e2124 2px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.burgerMenu{
    height: 60%; margin: 10px;
}
.burgerMenu img{
    height: 100%;
    cursor: pointer;
}

/* HEADER */
.headerIcon {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 46px;
    font-weight: bold;
}
.headerIcon img {
    height: 100%;
}
.spBaseBody {
    height: calc(100% - 80px); width: 100%;
}
</style>