<template>
    <div class="back-fields">
        <div class="inputFields">
            <iconTemp />
            <h2>ログイン</h2>
            <hr/>
            <p><b>ログイン用ID</b></p>
            <p><input name="username" type="text" v-model="user" /></p>
            <p><b>パスワード</b></p>
            <!-- <p><input name="password" type="password" v-model="password" v-on:keydown.enter="login" /></p> -->
            <p><PasswordInput v-model="password" @enter="login"></PasswordInput></p>
            <hr/>
            <p><a class="btn" @click="login">ログイン</a></p>
            <p v-if="videoAllowed" ><a class="btn" @click="videoLogger">VideoLogger<br />ログイン</a></p>
        </div>
    </div>
</template>
<style scoped>
@import url("../css/make.css");
</style>
<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import iconTemp from "@/components/items/iconTemp.vue"
import PasswordInput from "@/components/items/passwordInput.vue";
export default {
    components: {
    iconTemp,
    PasswordInput
},
    setup(){
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const user = ref();
        const password = ref();
        const videoAllowed = computed(() => {
            return store.getters["Video/allowed"];
        })

        const load = async () => {
            const loginCheck = await store.dispatch('Api/loginCheck');

            if(route.query.type == 'video') return;

            if(loginCheck && (location.hostname === "asogi.net" || location.hostname === "www2.asogi.net" || process.env.NODE_ENV == 'development')) {
                router.push('/u')
            }
        }
        load();

        const login = async () => {
            if(!password.value || !user.value){
                await store.dispatch('Common/showModal', {text: "ログイン用IDとパスワードは必須です。", move: false});
            }
            await store.dispatch('Api/Login', {url: "auth/auth", data: {
                username: user.value,
                password: password.value
            }})
            

            // if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
                // router.push('/sp');
            // } else {
                router.push('/u');
            // }
            
        }

        const videoLogger = async () => {
            user.value = "videoLoggerAccount";

            if(!password.value) {
                await store.dispatch('Common/showModal', {text: "パスワードは必須です。", move: false});
                return;
            }

            await store.dispatch('Video/auth', {username: user.value, password: password.value});
            router.push("/vl");
        }

        return {
            login,
            user,
            password,
            videoAllowed,
            videoLogger
        }
    }
}
</script>