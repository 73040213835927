<template>
    <tr :class="{isPriority: priority}">
        <td :style="{backgroundColor: vl.domColor}">&nbsp;&nbsp;</td>
        <td class="thumbnailsFav" @click="modal = true" :style="{backgroundColor: vl.domColor}"><img :src="vl.thumbnails"/></td>
        <td class="titleFav" @click="modal = true">{{vl.title}}</td>
        <td class="authorNameFav" @click="authorClick">{{vl.authorName || "取得中"}}</td>
        <td>{{subscFormat(vl.current)}}回/ {{subscFormat(vl.played)}}回(全期)</td>
        <td>{{ Math.floor(deviation * 100) / 100 }}</td>
        <td>{{insertAt}}<hr />{{ updateAt }}</td>
        <td>{{playedAt}}</td>
        <td><button class="favoButton tableChangeBtn" :class="{added: status}" @click="toggleFav">登録{{status ? "解除" : ""}}</button></td>
    </tr>
    <musicModalVue v-if="vl && modal" :music="vl" @closeMusicModal="modal=false" @priorityUpdate="load"></musicModalVue>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import musicModalVue from '@/components/modals/musicModal.vue';
import moment from 'moment';
import { subscFormat } from '@/utils/utils';

export default {
    components: {
        musicModalVue
    },
    props: {
        vl: Object,
        stdev: Number
    },
    setup(props, context){
        const store = useStore();
        const status = ref();
        const priority = ref();
        const modal = ref(false);
        const deviation = ref(null);
        const load = async () => {
            let st = await store.dispatch('Api/callApi', {url: "private/userMusicStatus", data: {url: props.vl.url}});
            status.value = st.favorite;
            priority.value = st.priority;
            if(props.stdev) deviation.value = await store.dispatch('Api/callApi', {url: "private/calcDeviation", data: {url: props.vl.url, stdev: props.stdev}});
        }
        load();
        const stdev_ = computed(() => {
            return props.stdev;
        })
        watch(stdev_, async () => {
            deviation.value = await store.dispatch('Api/callApi', {url: "private/calcDeviation", data: {url: props.vl.url, stdev: props.stdev}});
        })
        const toggleFav = async () => {
            let url = props.vl.url;

            await store.dispatch('Api/callApi', {url: "private/changeMusicStatus", data: {url, kind: "favorite", length: props.vl.length}});
            let st = await store.dispatch('Api/callApi', {url: "private/userMusicStatus", data: {url: props.vl.url}});
            status.value = st.favorite;
        }
        const insertAt = computed(() => {
            return formatDate(props.vl.mInsert);
        })
        const updateAt = computed(() => {
            return formatDate(props.vl.updateAt)
        });
        const playedAt = computed(() => {
            return formatDate(props.vl.lastPlayed);
        })
        const formatDate = (date) => {
            if(!date) return  "日付データがありません"
            let mom = moment(date);
            return mom.format('YYYY年MM月DD日 HH:mm:ss');
        }
        const authorClick = () => {
            if(props.vl.authorName) {
                context.emit('author', props.vl.authorName);
            }
        }
        return {
            status,
            toggleFav,
            modal,
            subscFormat,
            insertAt,
            playedAt,
            priority,
            load,
            deviation,
            authorClick,
            updateAt
        }
    }
}
</script>