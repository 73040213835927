<template>
    <Teleport to="body">
        <div class="bg-modal" v-on:click="close">
            <div class="base-modal" v-html="content"></div>
        </div>
    </Teleport>
</template>
<style scoped>
@import url('../../css/modal.css');
</style>
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    setup(){
        const store = useStore();
        const content = ref();
        const move = ref();

        const load = async () => {
            content.value = await store.getters["Common/getModalContent"];
            move.value = await store.getters["Common/getModalMove"];
        }
        load();

        const close = async () => {
            // console.log("close")
            await store.dispatch('Common/closeModal');
        }

        return{
            content,
            close
        }
    }
}
</script>