<template>
    <div>
        <h2>お知らせ投稿</h2>
        <p>お知らせを投稿します。</p>
        <hr />
        <table class="normal w100 nitifTable">
            <thead>
                <tr>
                    <th>タイトル</th>
                    <th>内容</th>
                    <th>横並び</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="message, index in messages" :key="index">
                    <td><input v-model="message.name" /></td>
                    <td><textarea v-model="message.value"></textarea></td>
                    <td>
                        <ToggleSwitch v-model="message.inline" />
                    </td>
                    <td>
                        <p v-if="0 < index"><a class="btn" @click="changeRow(index, -1)">上へ</a></p>
                        <p v-if="0 < index"><a class="btn" @click="removeRow(index)">削除</a></p>
                        <p v-if="index < messages.length - 1"><a class="btn" @click="changeRow(index, 1)">下へ</a></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><a class="btn" @click="pushRow">行を追加</a></td>
                </tr>
                <tr>
                    <td colspan="4"><a class="btn" @click="testSend" :class="{ disabled: !isSendPossible }">テスト送信</a>
                    </td>
                </tr>
                <tr v-if="tested">
                    <td colspan="4"><a class="btn" @click="send" :class="{ disabled: !isSendPossible }">送信</a></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import ToggleSwitch from '../items/toggleSwitch.vue';
const store = useStore();
const tested = ref(false);

const messages = ref([
    { name: "", value: "", inline: false }
]);

const load = async () => {
    const isAdmin = await store.dispatch('Api/callApi', {
        url: "private/checkAdmin"
    })

    if (!isAdmin) {
        await store.dispatch('Common/showModal', { text: "この機能を使う権限が有りません" });
        await store.dispatch('Common/setMenu', 0);
        return;
    }
}

const isSendPossible = computed(() => {
    let flag = true;
    for (let ms of messages.value) {
        if (ms.name == "" || ms.value == "") {
            flag = false;
            return flag;
        }
    }
    return flag
})

const pushRow = () => {
    messages.value.push({
        name: "",
        value: "",
        inline: false
    })
}

const removeRow = async (index) => {
    if (messages.value[index].name || messages.value[index].value) {
        if (!(await store.dispatch('Common/openConfirm', {
            text: "値が変更されています。削除しますか？"
        }))) return;
    }
    messages.value.splice(index, 1);
}
const changeRow = (index, kind) => {
    const mes = messages.value;
    [mes[index], mes[index + kind]] = [mes[index + kind], mes[index]];
}

const testSend = async () => {
    if (!isSendPossible.value) return;
    const resp = await store.dispatch('Api/callApi', {
        url: "private/testSend",
        data: {
            messages: messages.value
        }
    })

    if (resp) {
        tested.value = true;
        await store.dispatch('Common/showModal', { text: "テスト送信に成功しました" });
    } else {
        tested.value = false;
        await store.dispatch('Common/showModal', { text: "テスト送信に失敗しました" });
    }
}

const send = async () => {
    if (!tested.value) return;
    if (!(await store.dispatch('Common/openConfirm', {
        text: "購読中のユーザ全員に送信されます。よろしいですか？"
    }))) return;

    const resp = await store.dispatch('Api/callApi', {
        url: "private/notifSend",
        data: {
            messages: messages.value
        }
    })

    if (resp) {
        tested.value = false;
        await store.dispatch('Common/showModal', { text: "送信に成功しました" });
        messages.value = [
            { name: "", value: "", inline: false }
        ]
    } else {
        await store.dispatch('Common/showModal', { text: "送信に失敗しました" });
    }
}

onMounted(load);
</script>

<style scoped>
.nitifTable input {
    height: 150px;
    font-size: x-large;
}

.nitifTable textarea {
    height: 130px;
    width: calc(100% - 20px);
    min-width: 600px;
    padding: 10px;
    resize: none;
}
</style>