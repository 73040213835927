<template>
    <Teleport to="body">
        <div class="bg-modal" @click="close"></div>
        <div class="spMusicModalBody" v-if="video">
            <div class="thumbNails">
                <img :src="video.thumbnails" />
            </div>
            <div class="musicInfo">
                <p class="musicInfoTitle" @click="openURL(`https://youtu.be/${video.url}`)">{{ video.title }}</p>
                <hr/>
                <div v-if="video.author.id" class="authorContent">
                    <img class="authorThumb" :src="video.author.thumbnails || ''" />
                    <p><a>{{video.author.name}}</a><br />チャンネル登録者数: {{video.author.subscriber_count != undefined ? subscFormat(video.author.subscriber_count) + "人" : "非公開"}}</p>
                </div>
                <div v-else class="authorContent" title="取得中">
                    <img class="authorThumb" src="@/assets/Icon.png" />
                    <p><a>NiceMusic♪</a><br />投稿者情報取得中</p>
                </div>
                <p>YouTube再生回数: {{subscFormat(video.viewCount)}}回<br /></p>
                <p v-if="video.played !== undefined && video.current !== undefined"><span v-if="kind != 'allFavos'">自分の</span>再生回数: {{subscFormat(video.current)}}回/ {{subscFormat(video.played)}}回(全期)</p>
                <p>BOT内再生回数    :  <span v-if="video.currentCnt === undefined">{{subscFormat(list)}}回</span><span v-else>{{subscFormat(video.currentCnt)}}回/ {{subscFormat(list)}}回(全期)</span></p>
                <p>お気に入り登録者数: {{subscFormat(fav)}}人</p>

                <div class="registedLists" v-if="usersData">
                    <template v-if="usersData.favorites.length">
                        <p><b>お気に入り登録者(公開者のみ): {{usersData.favorites.length}}人</b></p>
                        <div class="registedListsUsers">
                            <UsersIcon
                                v-for="fv in usersData.favorites"
                                :key="fv"
                                :userData="usersData.usersData[fv]"
                            ></UsersIcon>
                        </div>
                    </template>
                    <template v-if="usersData.blocked.length">
                        <p><b>ブロック者: {{usersData.blocked.length}}人</b></p>
                        <div class="registedListsUsers">
                            <UsersIcon
                                v-for="fv in usersData.blocked"
                                :key="fv"
                                :userData="usersData.usersData[fv]"
                            ></UsersIcon>
                        </div>
                    </template>
                </div>

                <div class="changeButtons" v-if="musicStatus">
                    <a class="favoButton changeButton" @click="toggleFav" :class="{added: musicStatus.favorite}">登録{{musicStatus.favorite ? "解除" : ""}}</a>
                    <a class="blockButton changeButton" @click="toggleBlk" :class="{added: musicStatus.blocked}">ブロック{{musicStatus.blocked ? "解除" : ""}}</a>
                    <a v-if="joining" class="changeButton orderBtn" @click="AddOrder()">予約</a>
                    <a v-if="isAdmin" class="changeButton updateBtn" @click="refreshInfo">更新</a>
                </div>

                <template v-if="musicStatus && musicStatus.favorite">
                    <p class="priorityVideo">優先動画: <ToggleSwitch v-model="musicStatus.priority" @change="priorityChange"></ToggleSwitch></p>
                </template>
                <template v-if="musicVolume !== null">
                    <p class="musicVolume">個別音量: <input type="number" v-model="musicVolume" @change="musicVolumeSet" step="0.1" min="-1" max="1" /></p>
                </template>
            </div>
        </div>
    </Teleport>
</template>
<script>
import { useStore } from 'vuex'
import { subscFormat } from '@/utils/utils';
import { ref } from 'vue';
import ToggleSwitch from '@/components/items/toggleSwitch.vue';
import UsersIcon from '../../items/usersIcon.vue';

export default {
    props: {
        video: Object
    },
    setup(props, context) {
        const store = useStore();
        const musicInfoText = ref("");
        const musicStatus = ref();
        const fav = ref(0);
        const list = ref(0);
        const musicVolume = ref(0);
        const joining = ref();
        const usersData = ref(null);
        const isAdmin = ref(false);
        const close = () => {
            context.emit("close");
        };
        const openURL = async (url) => {
            const cnf = await store.dispatch("Common/openConfirm", { header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}` });
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
        };
        const load = async () => {
            isAdmin.value = await store.dispatch("Api/callApi", { url: "private/isAdmin" });
            musicInfoText.value = "";
            switch (props.kind) {
                case "favorite":
                    musicInfoText.value = `お気に入り登録者数: ${subscFormat(props.video.cnt)}人`;
                    break;
                case "view":
                    musicInfoText.value = `BOT再生回数: ${subscFormat(props.video.cnt)}回`;
                    break;
            }
            musicStatus.value = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.video.url } });
            musicVolume.value = musicStatus.value.volume;
            let stt = await store.dispatch("Api/callApi", { url: "private/getVideoStatus", data: { url: props.video.url } });
            fav.value = stt["fav"];
            list.value = stt["played"];
            joining.value = await store.dispatch("Api/callApi", { url: "private/joiningListeningVC" });
            // console.log(joining.value);
            usersData.value = await store.dispatch("Api/callApi", { url: "private/getRegistedUser", data: {
                    url: props.video.url
                } });
            // console.log(usersData.value);
            // console.log(props.video.author)
        };
        load();
        const toggleFav = async () => {
            let url = props.video.url;
            await store.dispatch("Api/callApi", { url: "private/changeMusicStatus", data: { url, kind: "favorite", length: props.video.length } });
            musicStatus.value = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.video.url } });
            let stt = await store.dispatch("Api/callApi", { url: "private/getVideoStatus", data: { url: props.video.url } });
            fav.value = stt["fav"];
            list.value = stt["played"];
        };
        const toggleBlk = async () => {
            let url = props.video.url;
            await store.dispatch("Api/callApi", { url: "private/changeMusicStatus", data: { url, kind: "block", length: props.video.length } });
            musicStatus.value = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.video.url } });
            let stt = await store.dispatch("Api/callApi", { url: "private/getVideoStatus", data: { url: props.video.url } });
            fav.value = stt["fav"];
            list.value = stt["played"];
        };
        const AddOrder = async () => {
            //await store.dispatch('Common/showModal', {text: "変更しました。"});
            let data = {
                vid: props.video.url,
                gid: joining.value.gid
            };
            let resp = await store.dispatch("Api/callApi", { url: "private/AddOrder", data });
            if (resp) {
                await store.dispatch("Common/showModal", { text: "予約しました。" });
            }
            else {
                await store.dispatch("Common/showModal", { text: "予約に失敗しました。" });
            }
        };
        const refreshInfo = async () => {
            const resp = await store.dispatch("Api/callApi", { url: "private/updateMusicInfo", data: { url: props.video.url } });
            if (resp) {
                await store.dispatch("Common/showModal", { text: resp.message });
                if (resp.result) {
                    load();
                }
                else {
                    context.emit("closeMusicModal");
                }
            }
        };
        const priorityChange = async () => {
            await store.dispatch("Api/callApi", { url: "private/changeMusicPriority", data: { url: props.video.url, value: musicStatus.value.priority } });
            context.emit("priorityUpdate");
            load();
        };

        const musicVolumeSet = async () => {
            if(musicVolume.value < -1 || 1 < musicVolume.value) {
                await store.dispatch('Common/showModal', {text: "音量は-1以上1以下である必要があります。"})
                if(musicVolume.value < -1) {
                    musicVolume.value = -1;
                }else {
                    musicVolume.value = 1;
                }
                return;
            }

            let resp = await store.dispatch('Api/callApi', {url: "private/changeMusicVolume", data: {
                url: props.music.url, volume: musicVolume.value
            }});

            musicVolume.value = resp;
        }

        return {
            close,
            openURL,
            subscFormat,
            refreshInfo,
            priorityChange,
            toggleBlk,
            toggleFav,
            AddOrder,
            musicInfoText,
            musicStatus,
            fav,
            list,
            joining,
            usersData,
            isAdmin,
            musicVolumeSet,
            musicVolume
        };
    },
    components: { ToggleSwitch, UsersIcon }
}
</script>
<style scoped>
.bg-modal {
    animation-name:fadeInAnime;
    animation-duration:1s;
    animation-fill-mode:forwards;
    opacity:0;
}
.spMusicModalBody {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%; width: 80%;
    background-color: #282b30;
    animation-name:fadeInAnime;
    animation-duration:1s;
    animation-fill-mode:forwards;
    opacity:0;
    overflow-y: auto;
    color: white;
}
.thumbNails, .thumbNails img {
    width: 100%;
}
.musicInfo{
    width: calc(100% - 30px);
    padding: 15px;
}
.musicInfoTitle{
    width: 100%;
    text-align: center;
    text-decoration: underline;
}
.authorContent{
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;
    background-color: #1e2124;
    margin-bottom: 10px;
}
.authorContent .authorThumb{
    width: 40px;
}
.authorContent p{
    margin-left: 1em;
    /* text-decoration: underline; */
    font-weight: bold;
    font-size: 16px;
}
.updateBtn{
    color: rgb(118, 150, 3);
    border-color: rgb(118, 150, 3);
    transition: 0.5s;
}
.updateBtn:hover{
    color: white;
    background-color: rgb(118, 150, 3);
}
.updateBtn:active{
    transition: none;
    transform: scale(0.9)
}
.priorityVideo{
    display: flex;
    align-items: center;
}
.musicVolume input {
    text-align: right;
}
</style>