<template>
    <div class="imageScrolls">
        <div class="imageScrollItem" v-for="image in images" :key="image" :title="image.title" @click="openModal(image)">
            <img :src="image.thumbnails" />
            <p class="title">{{image.title}}</p>
        </div>
    </div>
    <musicModalVue v-if="music" :music="music" :kind="kind" @closeMusicModal="openModal()"></musicModalVue>
</template>
<script>
import musicModalVue from '@/components/modals/musicModal.vue'
import { ref } from 'vue';
export default {
    props: {
        images: Object,
        kind: String,
    },
    setup() {
        const music = ref();

        const openModal = (value) => {
            music.value = value;
        }

        return{
            music,
            openModal
        }
    },
    components: {
        musicModalVue
    }
}
</script>
<style scoped>
@import url("@/css/modal.css");
.imageScrolls{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}
.imageScrollItem{
    width: 19%;
    cursor: pointer; user-select: none;
}
.imageScrollItem img{
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
    aspect-ratio: 16 / 9;
}

.imageScrollItem p.title{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
}
</style>

<!-- 16:9 = 280:x  = 16x = 280*9 = x=280*9/16 -->