import axios from 'axios';
import createPersitstedState from 'vuex-persistedstate';
const PORT = 3007;
const baseURL = (process.env.NODE_ENV == 'development') ? `http://localhost:${PORT}/` : `https://asogi.net:3007/`;
const state = ()=> ({
    allowed: false,
    token: "",
    menu: 0
});
const mutations = {
    setAllowed(state, value) {
        state.allowed = value;
    },
    setToken(state, value) {
        state.token = value
    },
    setMenu(stete, value) {
        stete.menu = value
    }
};
const actions = {
    ping(context) {
        return new Promise((res, rej) => {
            axios({
                method: "POST",
                url: baseURL,
                timeout: 1000
            }).then(resp => {
                // console.log(resp.data);
                if(resp.data.result) {
                    context.commit('setAllowed', true);
                }
                res(true);
            }).catch((err) => {
                context.commit('setAllowed', false);
                rej(err);
            })
        })
    },
    auth(context, data) {
        return new Promise((res, rej) => {
            axios({
                method: "POST",
                url: baseURL + "auth",
                data,
            }).then(resp => {
                context.commit("setToken", resp.data.token);
                res(true);
            }).catch((err) => {
                rej(err);
            })
        })
    },
    setMenu(context, value) {
        context.commit('setMenu', value);
    },
    logout(context) {
        context.commit('setToken', "");
    },
    setToken(context, value) {
        context.commit('setToken', value);
    },
    call(context, obj) {
        const token = context.commit('Common/addLoadingContent', null, {root: true});
        return new Promise((res, rej) => {
            axios({
                method: "POST",
                url: `${baseURL}${obj.url}`,
                data: obj.data,
                headers: {
                    'Authorization': `bearer ${context.state.token}`
                }
            }).then(resp => {
                res(resp.data);
            }).catch((err) => {
                rej(err);
            }).finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        })
    }
};
const getters = {
    allowed: state => {return state.allowed},
    token: stete => {return stete.token},
    menu: state => {return state.menu}
};

export default{
    namespaced: true,
    state: state(),
    mutations,
    actions,
    getters,
    plugins: [createPersitstedState({
        strage: window.sessionStorage
    })]
}