<template>
    <Teleport to="body">
        <div class="bg-modal" @click="cancel"></div>
        <div class="imageCropModal">
            <div class="cropField">
                <VueCropper v-if="image" :src="image" ref="cropper" aspectRatio="1" @cropstart="croppingPreview" @cropend="croppingPreview" />
            </div>
            <div class="cropBtns">
                <div class="preview">
                     <img :src="croppingPreviewSrc" />
                </div>
                <!-- <croppingPreviewSrc /> -->
                <p><a class="btn" @click="cancel">キャンセル</a><a class="btn" @click="cropBtn">確定</a></p>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import VueCropper from 'vue-cropperjs';

const cropper = ref(null);
const image = ref(null);
const props = defineProps({
    file: {type: File}
});
const cropping = ref(null);

const emit = defineEmits(['close', 'cancel', 'preview', "cropped"]);

const file = computed(() => {
    return props.file;
})

const close = () => {
    emit('close');
}
const cancel = () => {
    emit('cancel');
    close();
}

const load = () => {
    createImage();
}

const cropBtn = () => {
    // cropper.value.crop();
    if(!cropper.value) return;
    const data = cropper.value.cropper.getCroppedCanvas({w: 500, h: 500}) || null;
    emit('cropped', data);
    close();
}

const createImage = () => {
    // console.log(file.value)
    if(!file.value) return null;

    const reader = new FileReader();

    reader.onload = e => {
        image.value = e.target.result;
    }
    reader.readAsDataURL(file.value);
}
const croppingPreviewSrc = computed(() => {
    if(!cropping.value) return null;
    // console.log(cropping.value)
    return cropping.value?.target?.cropper?.getCroppedCanvas({w: 500, h: 500})?.toDataURL('image/jpeg') || null;
})
const croppingPreview = (event) => {
    cropping.value = event;
    emit('preview', croppingPreviewSrc.value);
}
onMounted(load)
</script>

<style scoped>
.imageCropModal{
    position: absolute;
    width: 80%;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    background-color: #36393F;
    display: flex;
    flex-wrap: nowrap;
}
.bg-modal {
    cursor: pointer;
}
.cropField{
    width: 80%;
}
.cropBtns{
    width: 20%;
}
.preview {
    width: 100%;
}
.preview img {
    width: 100%;
}
</style>