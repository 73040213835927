<template>
    <div class="videoMainBase">
        <h2>最新履歴</h2>
        <p>最新の履歴を表示します。履歴は12時間保持されます。</p>
        <p>表示件数: <select v-model="viewMax">
                <option :value="10">10</option>
                <option :value="videoLength">全</option>
            </select>件</p>
        <br />
        <p>{{ videoLength }}件登録中</p>
        <PageNation v-model="current" :maxPage="maxView" :keyObserb="true" :viewMax="viewMax"></PageNation>
        <table class="normal w100">
            <thead>
                <tr>
                    <th>サムネイル</th>
                    <th>タイトル</th>
                    <th>サイト</th>
                    <th>登録日</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="video of viewList" :key="video">
                    <td class="videoThumb"><a :href="video.url" target="_blank"><img :src="video.images" /></a></td>
                    <td><a :href="video.url" target="_blank">{{ video.title }}</a></td>
                    <td>{{ video.webName }}</td>
                    <td>{{ formatDate(video.insertAt) }}</td>
                    <td><a class="btn" @click="modalValue = video">登録</a></td>
                </tr>
                <tr v-if="videoLength == 0">
                    <td colspan="5">登録されている動画がありません<br />以下で探してください
                        <p v-for="site, index in siteList" :key="index"><a target="_blank" :href="site[0]"
                                :title="site[1]">動画サイト{{ index + 1 }}</a></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <PageNation v-model="current" :maxPage="maxView" :viewMax="viewMax"></PageNation>
    </div>
    <registTag v-if="modalValue" :video="modalValue" @close="modalValue = null" @load="load"></registTag>
</template>
<script>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import PageNation from '../items/pageNation.vue';
import { useStore } from 'vuex';
import { formatDate } from '@/utils/utils';
import registTag from "@/components/videoLogger/item/registTag.vue"

export default {
    setup() {
        const store = useStore();
        const current = ref(1);
        const siteList = ref([]);
        const videos = ref([]);
        const viewMax = ref(10);
        const modalValue = ref(null);
        const maxView = computed(() => {
            return Math.ceil(videos.value.length / viewMax.value)
        });

        const viewList = computed(() => {
            let arry = [];
            let end = current.value * viewMax.value;
            let start = end - viewMax.value;
            arry = videos.value.slice(start, end);
            return arry;
        });

        const load = async () => {
            videos.value = await store.dispatch('Video/call', { url: "videos/currentVideo" })
            siteList.value = await store.dispatch("Video/call", { url: "videos/siteLists" });
        }
        load();

        const videoLength = computed(() => { return videos.value.length || 0 })

        const focus = () => {
            load();
        }
        onMounted(() => {
            window.addEventListener("focus", focus, false);
        })
        onUnmounted(() => {
            window.removeEventListener('focus', focus);
        })

        return {
            viewList,
            maxView,
            current,
            formatDate,
            siteList,
            modalValue,
            load,
            videoLength,
            viewMax,
        }
    },
    components: { PageNation, registTag }
}
</script>