<template>
    <div class="videoLoggerBase">
        <div class="menuLists">
            <MenuView :menuLists="menuLists"></MenuView>
        </div>
        <div class="mainColumn">
            <component :is="viewComponent"></component>
        </div>
    </div>
</template>

<script>
import MenuView from '@/components/videoLogger/menuView.vue';
import TopVideoLogger from '@/components/videoLogger/topVideoLogger.vue';
import historyLogger from '@/components/videoLogger/historyLogger.vue'
import { computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import FavoriteLogger from '@/components/videoLogger/favoriteLogger.vue';
import SettingLogger from '@/components/videoLogger/settingLogger.vue';
import TrashVue from '@/components/videoLogger/trashVue.vue';
import AutoDeleted from '@/components/videoLogger/autoDeleted.vue';

export default {
    setup() {
        const store = useStore();
        const token = computed(() => { return store.getters["Video/token"]; });
        const router = useRouter();
        const route = useRoute();
        const menuId = computed(() => {return store.getters["Video/menu"]})
        const menuLists = [
            {label: "TOP", component: TopVideoLogger},
            {label: "History", component: historyLogger},
            {label: "Favorite", component: FavoriteLogger},
            {label: "Old Video", component: TrashVue},
            {label: "Auto Deleted", component: AutoDeleted},
            {label: "Setting", component: SettingLogger}
        ];
        const videoAllowed = computed(() => {
            return store.getters["Video/allowed"];
        })
        const viewComponent = computed(() => {
            return menuLists[menuId.value].component;
        })

        watch(token, (v) => {
            if (!v)
                router.push("/");
        });

        watch(videoAllowed, (v) => {
            if (!v)
                router.push("/");
        })

        const load = async () => {
            const paramToken = route.query.token;

            if(videoAllowed.value && !token.value && paramToken) {
                await store.dispatch('Video/setToken', paramToken);
            }

            await store.dispatch('Video/ping');
            if (!token.value || !videoAllowed.value)
                router.push("/");
        };
        load();

        return {
            menuLists,
            viewComponent
        }
    },
    components: { MenuView }
}
</script>

<style scoped>
@import url("../css/videoLogger.css");
.videoLoggerBase{
    width: 100%; height: 100%;
    background-color: #1e2124;
    display: flex;
    color: white;
    font-size: 16px;
}
.menuLists{
    width: 20%; height: 100%;
    overflow-y: auto;
    background-color: #36393e;
}
.mainColumn{
    width: 80%; height: 100%;
    background-color: #424549;
}
</style>