<template>
    <tr>
        <td class="thumbnailsFav" @click="modal = true"><img :src="vl.thumbnails"/></td>
        <td class="titleFav" @click="modal = true">{{vl.title}}</td>
        <td>{{vl.author.name}}</td>
        <td>{{insertAt}}</td>
        <td>{{playedAt}}</td>
        <td><button class="favoButton tableChangeBtn" :class="{added: status}" @click="toggleFav">登録{{status ? "解除" : ""}}</button></td>
    </tr>
    <musicModalVue v-if="vl && modal" :music="vl" @closeMusicModal="modal=false"></musicModalVue>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import musicModalVue from '@/components/modals/musicModal.vue';
import moment from 'moment';

export default {
    components: {
        musicModalVue
    },
    props: {
        vl: Object
    },
    setup(props){
        const store = useStore();
        const status = ref();
        const modal = ref(false);
        const load = async () => {
            let st = await store.dispatch('Api/callApi', {url: "private/userMusicStatus", data: {url: props.vl.url}});
            status.value = st.favorite;
        }
        load();
        const toggleFav = async () => {
            let url = props.vl.url;

            await store.dispatch('Api/callApi', {url: "private/changeMusicStatus", data: {url, kind: "favorite", length: props.vl.length}});
            let st = await store.dispatch('Api/callApi', {url: "private/userMusicStatus", data: {url: props.vl.url}});
            status.value = st.favorite;
        }
        const insertAt = computed(() => {
            return formatDate(props.vl.mInsert);
        })
        const playedAt = computed(() => {
            return formatDate(props.vl.lastPlayed);
        })
        const formatDate = (date) => {
            let mom = moment(date);
            return mom.format('YYYY年MM月DD日 kk:mm:ss');
        }
        return {
            status,
            toggleFav,
            modal,
            insertAt,
            playedAt
        }
    }
}
</script>