<template>
    <Teleport to="body" v-if="video">
        <div class="bg-modal" @click="closeModal"></div>
        <div class="registModal">
            <p><img :src="video.images" /></p>
            <p><u><b>{{ video.title }}</b></u></p>
            <p>タグを追加する <select v-model="currentTag" @change="inputSelectiongTags">
                <option v-for="(tag, index) in tags" :key="index" :value="index" :hidden="selectingTag.includes(index)">{{ tag }}</option>
            </select></p>
            <div class="tagListsArea">
                <div class="selectiongTag" v-for="tag in selectingTag" :key="tag" title="解除する" @click="removeSelectingTags(tag)"><span>&#x00d7;</span> {{ tags[tag] }}</div>
            </div>
            <div style="text-align: center;"><a class="btn" @click="registCurrent">登録</a></div>
        </div>
    </Teleport>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        video: Object
    },
    setup(props, context) {
        const store = useStore();
        const tags = ref({});
        const currentTag = ref("");
        const selectingTag = ref([]);

        const inputSelectiongTags = () => {
            selectingTag.value.push(currentTag.value);
            currentTag.value = "";
        }

        const removeSelectingTags = (name) => {
            selectingTag.value = selectingTag.value.filter( v => v!=name);
        }

        const closeModal = () => {
            context.emit('close');
        }

        const registTags = computed(() => {
            const resp = {};
            for(let sl of selectingTag.value ) resp[sl] = sl;
            // console.log(resp);
            return resp;
        })

        const registCurrent = async () => {
            const resp = await store.dispatch('Video/call', {url: "videos/registCurrent", data: {url: props.video.url, tags: registTags.value}})

            if(resp){
                store.dispatch('Common/showModal', {text: "登録しました."})
                context.emit('load');
                context.emit('close');
            }else {
                store.dispatch('Common/showModal', {text: "登録に失敗しました"})
            }
        }

        const load = async () => {
            tags.value = await store.dispatch('Video/call', {url: "videos/getTags"});
        }
        load();

        return {
            closeModal,
            tags,
            currentTag,
            selectingTag,
            inputSelectiongTags,
            removeSelectingTags,
            registCurrent
        }
    }
}
</script>

<style scoped>
.bg-modal{
    cursor: pointer;
}
.registModal{
    width: 40%;
    background-color: 	#424549;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 1em;
}
.registModal img {
    width: 100%;
}
.registModal p:not(a) {
    color: white;
    text-align: center;
}
.tagListsArea{
    padding: 1em;
    background-color: #1e2124;
    text-align: center;
}
.selectiongTag{
    color: white;
    background-color: 	#424549;
    display: inline-block;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    cursor: pointer;
    margin: 0.25em;
    user-select: none;
}
</style>