<template>
    <div class="back-fields">
        <div class="inputFields">
            <iconTemp />
            <h2>利用者登録</h2>
            <hr/>
            <p><b>登録用トークン</b></p>
            <p><input type="text" readonly v-model="token" /></p>
            <p><b>表示名</b></p>
            <p><input type="text" readonly v-model="name" /></p>
            <p><b>ログイン用ID</b></p>
            <p><input type="text" v-model="user" @change="checkId" /></p>
            <small v-if="checkIdrslt">
                <span v-if="checkIdrslt.result">✅</span>
                <span v-else>❌</span>
                <span :class="{ok: checkIdrslt.result, ng: !checkIdrslt.result}">{{checkIdrslt.msg}}</span>
            </small>
            <p><b>パスワード</b></p>
            <p><PasswordInput v-model="password"></PasswordInput></p>
            <p><b>パスワード確認</b></p>
            <p><PasswordInput v-model="password2"></PasswordInput></p>
            <hr/>
            <a class="btn" v-on:click="submit">登録</a>
        </div>
    </div>
</template>
<style scoped>
@import url("../css/make.css");
</style>
<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import iconTemp from "@/components/items/iconTemp.vue"
import PasswordInput from '@/components/items/passwordInput.vue';

export default {
    components: {
    iconTemp,
    PasswordInput
},
    setup(){
        const store = useStore();
        const token = ref();
        const route = useRoute();
        const router = useRouter();
        const name = ref("");
        const password = ref("");
        const password2 = ref("");
        const user = ref("");
        const checkIdrslt = ref();

        const checkId = async () => {
            checkIdrslt.value = await store.dispatch('Api/callNormalApi', {url: "auth/searchAlready", data: {user: user.value}});
        }

        // console.log(route.params);
        const ready = async ()=>{
            token.value = route.params.id;

            if(!token.value){
                alert("URLが不正です。");
                router.push("/");
            }

            let check = await store.dispatch('Api/callNormalApi', {
                url: "auth/checkToken",
                data: {
                    token: token.value
                }
            });

            name.value = check.userData.username
        }
        ready();

        const submit = async () => {
            let err = [];
            // console.log([user.value, password.value, password2.value])

            if(!checkIdrslt.value.result) return;

            let reg = /^[0-9a-zA-Z]+$/;

            if(!user.value) err.push("ログイン用IDが入力されていません");
            else if(user.value.length < 5) err.push("ログイン用IDは5文字以上である必要があります。");
            else if(!reg.test(user.value)) err.push("ログイン用IDは半角英数のみである必要があります")

            if(!password.value) err.push('パスワードが入力されていません。');
            else if(password.value.length < 8) err.push("パスワードは8文字以上である必要があります");

            if(password.value != password2.value) err.push("パスワードとパスワード確認が一致していません");

            if(err.length){
                await store.dispatch('Common/showModal', {text: err.join("<br />"), move: false});
                return;
            }


            let data = {
                password: password.value,
                user: user.value,
                token: token.value
            }

            let rs = await store.dispatch('Api/callNormalApi', {url: "auth/registAccount", data});
            if(rs){
                await store.dispatch('Common/showModal', {text: "登録が完了しました", move: true});
            }else{
                await store.dispatch('Common/showModal', {text: "登録に失敗しました", move: false});
            }
        }

        return {
            token,
            name,
            password,
            password2,
            user,
            submit,
            checkId,
            checkIdrslt
        }
    }
}
</script>