<template>
    <div class="colorPaletDiv">
        <button class="colorButton" @click="input('§0')">
            <div class="colorIcon" style="background-color: rgb(0, 0, 0);"></div>
        </button>
        <button class="colorButton" @click="input('§1')">
            <div class="colorIcon" style="background-color: rgb(0, 0, 170);" ></div>
        </button>
        <button class="colorButton" @click="input('§2')">
            <div class="colorIcon" style="background-color: rgb(0, 170, 0);"></div>
        </button>
        <button class="colorButton" @click="input('§3')">
            <div class="colorIcon" style="background-color: rgb(0, 170, 170);"></div>
        </button>
        <button class="colorButton" @click="input('§4')">
            <div class="colorIcon" style="background-color: rgb(170, 0, 0);"></div>
        </button>
        <button class="colorButton" @click="input('§5')">
            <div class="colorIcon" style="background-color: rgb(170, 0, 170);"></div>
        </button>
        <button class="colorButton" @click="input('§6')">
            <div class="colorIcon" style="background-color: rgb(255, 170, 0);"></div>
        </button>
        <button class="colorButton" @click="input('§7')">
            <div class="colorIcon" style="background-color: rgb(170, 170, 170);"></div>
        </button>
        <button class="colorButton" @click="input('§8')">
            <div class="colorIcon" style="background-color: rgb(85, 85, 85);"></div>
        </button>
        <button class="colorButton" @click="input('§9')">
            <div class="colorIcon" style="background-color: rgb(85, 85, 255);"></div>
        </button>
        <button class="colorButton" @click="input('§a')">
            <div class="colorIcon" style="background-color: rgb(85, 255, 85);"></div>
        </button>
        <button class="colorButton" @click="input('§b')">
            <div class="colorIcon" style="background-color: rgb(85, 255, 255);"></div>
        </button>
        <button class="colorButton" @click="input('§c')">
            <div class="colorIcon" style="background-color: rgb(255, 85, 85);"></div>
        </button>
        <button class="colorButton" @click="input('§d')">
            <div class="colorIcon" style="background-color: rgb(255, 85, 255);"></div>
        </button>
        <button class="colorButton" @click="input('§e')">
            <div class="colorIcon" style="background-color: rgb(255, 255, 85);"></div>
        </button>
        <button class="colorButton" @click="input('§f')">
            <div class="colorIcon" style="background-color: rgb(255, 255, 255);"></div>
        </button>
        <button class="colorButton" @click="input('§g')">
            <div class="colorIcon" style="background-color: rgb(221, 214, 5);"></div>
        </button>
        <button class="colorButton" @click="input('§l')">
            <b>B</b>
        </button>
        <button class="colorButton" @click="input('§m')">
            <s>S</s>
        </button>
        <button class="colorButton" @click="input('§n')">
            <div style="text-decoration: underline;">U</div>
        </button>
        <button class="colorButton" @click="input('§o')">
            <div style="font-style: italic;">I</div>
        </button>
        <button class="colorButton" @click="input('§r')">
            <div>リセット</div>
        </button>
        <button class="colorButton" @click="clear">クリア</button>
        <!----><!----><!---->
    </div>
</template>
<script>
import { useStore } from 'vuex';

export default {
    props: {
        id: String,
        modelValue: String
    },
    setup(props, context){
        const store = useStore();

        const input = (txt) => {
            let str = props.modelValue;

            let txtArea = document.getElementById(props.id);
            // console.log(document.activeElement);

            if(!txtArea) {
                store.dispatch('Common/showModal', {text: "エラーが発生しました。 err: 01"});
                return;
            }

            let start = txtArea.selectionStart;

            // console.log(start, end, dif);

            let hofe = str.substring(0, start) + txt + str.substring(start);

            context.emit('update:modelValue', hofe);
            txtArea.select();
        }

        const clear = () => {
            context.emit('update:modelValue', "");
        }
        
        return {
            clear,
            input
        }
    }
}
</script>
<style scoped>
.colorButton{
    padding: 0.5em;
}
.colorIcon{
    width: 10px;
    height: 10px;
}
</style>