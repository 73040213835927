<template>
    <Teleport to="body">
        <div class="bg-modal" v-on:click="close"></div>
        <div class="settingModal">
            <table class="normal w100">
                <tbody>
                    <tr>
                        <th>識別番号</th>
                        <td colspan="2">{{ fp }}</td>
                    </tr>
                    <tr>
                        <th>識別名</th>
                        <td colspan="2"><input type="text" v-model="browseName" /></td>
                    </tr>
                    <tr>
                        <th></th>
                        <td><a class="btn" @click="close">キャンセル</a></td>
                        <td><a class="btn" @click="regist">登録</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Teleport>
</template>
<style scoped>
@import url('../../css/modal.css');
</style>
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        fp: String
    },
    setup(props, context) {
        const browseName = ref("");
        const store = useStore();

        const close = async () => {
            context.emit('close');
        }

        const load = async () => {
            browseName.value = checkBrowse();
        }
        load();

        function checkBrowse() {
            let userAgent = window.navigator.userAgent.toLowerCase();

            if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
                return "InternetExplorer"
            } else if (userAgent.indexOf('edge') != -1) {
                return "Edge"
            } else if (userAgent.indexOf('chrome') != -1) {
                return "Chrome"
            } else if (userAgent.indexOf('safari') != -1) {
                return "Safari"
            } else if (userAgent.indexOf('firefox') != -1) {
                return "firefox"
            } else if (userAgent.indexOf('opera') != -1) {
                return "Opera"
            } else {
                return ""
            }
        }

        const regist = async () => {
            let rs = await store.dispatch('Api/callApi', {
                url: "private/registFprints", data: {
                    name: browseName.value,
                    fp: props.fp
                }
            })
            await store.dispatch('Common/showModal', { text: rs ? "登録しました" : "登録に失敗しました。" });

            if (rs) context.emit('registed');
        }

        return {
            close,
            browseName,
            regist
        }
    }
}
</script>