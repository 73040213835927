<template>
    <div>
        <h2>解析</h2>
        <p>過去の再生履歴を解析できます</p>
        <hr />
        <div class="search_field">
            <p>
                開始日:
                <select v-model="startTime">
                    <option v-for="kp in keyPattern" :key="kp" :value="kp">{{ kp }}</option>
                </select>
            </p>
            <p>
                終了日:
                <select v-model="endTime">
                    <option v-for="kp in endPattern" :key="kp" :value="kp">{{ kp }}</option>
                </select>
            </p>
            <p>
                全期再生回数: <toggleSwitch v-model="playedView" />
            </p>
            <p>
                短期再生回数: <toggleSwitch v-model="currentView" />
            </p>
        </div>
        <div class="chartBox">
            <Line v-if="graphData" :key="graphData" :data="graphData.data" />
        </div>
    </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js';
import { Line } from 'vue-chartjs'
import moment from 'moment';
import toggleSwitch from '../items/toggleSwitch.vue';

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale);

const store = useStore();
const keyPattern = ref([]);
const startTime = ref();
const endTime = ref();
const analyzeData = ref();
const playedView = ref(true);
const currentView = ref(true);

const requestOption = computed(() => {
    if (!startTime.value || !endTime.value) return null;
    return {
        startTime: startTime.value,
        endTime: endTime.value,
        current: currentView.value,
        played: playedView.value
    }
})

const endPattern = computed(() => {
    if (!startTime.value) return [];
    const indexOf = keyPattern.value.indexOf(startTime.value);
    if (indexOf < 0) return [];
    return keyPattern.value.slice(indexOf + 1);
})

const graphData = computed(() => {
    if (!analyzeData.value) return null;

    const labels = [];
    const datasets = [];
    let played = [];
    let current = [];
    for (let data of analyzeData.value) {
        const date = moment(data.insertAt);
        const label = date.format('YYYY年MM月DD日(ddd) HH:mm');
        if (!labels.includes(label)) labels.push(label);

        if (playedView.value) played.push(data.played || 0);
        if (currentView.value) current.push(data.current || 0) ;
    }

    if (played.length) {
        datasets.push({
            label: "全期再生回数",
            backgroundColor: "red",
            fill: true,
            data: played,
        })
    }
    if (current.length) {
        datasets.push({
            label: "短期再生回数",
            backgroundColor: "blue",
            fill: true,
            data: current,
            yAxisID: "y-axis-2"
        })
    }

    const data = {
        labels,
        datasets
    }

    const option = {
        scales: {
            yAxes: [
                {
                    // 左の軸。設定はデフォルトのまま
                    position: "left"
                },
                {
                    id: "y-axis-2",
                    position: "right" // 右に表示する
                }
            ]
        }
    }

    const resp = {
        data,
        option
    }

    return resp;
})

watch(endPattern, () => {
    if (endTime.value < endPattern.value[0]) endTime.value = endPattern.value[0]
}, { deep: true })

watch(requestOption, async (v) => {
    analyzeData.value = await store.dispatch("Api/callApi", {
        url: "private/analyze",
        data: {
            option: v
        }
    })
}, { deep: true })

const load = async () => {
    keyPattern.value = await store.dispatch("Api/callApi", {
        url: "private/analyzeDefault"
    })
    startTime.value = keyPattern.value[0];
    endTime.value = keyPattern.value[keyPattern.value.length - 1];
}

onMounted(load);
</script>

<style scoped>
.chartBox {
    background-color: white;
}
.search_field{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>