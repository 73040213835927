<template>
    <template v-if="gid !== null">
        <div>
            <h2>Webコントローラー</h2>
            <p>NicemusicをWebからコントロールできます。</p>
            <br />
            <hr />
            <!-- コントローラー -->
            <div class="controllerBody">
                {{ userInfo.globalName || userInfo.username }}さんこんにちは
                <p class="controllerRow">
                    <a class="btn" @click="command('stop')" title="再生終了">■</a>
                    <a class="btn" @click="command('next')" title="次の曲">▶▶</a>
                    <button class="favoButton tableChangeBtn" @click="toggleFav" :class="{ added: favorite }"
                        v-if="videoInfo">{{ favorite ?
        "登録解除" : "登録" }}</button>
                    <button class="blockButton tableChangeBtn" @click="toggleBlock" :class="{ added: blocked }"
                        v-if="videoInfo">{{ blocked ?
        "ブロック解除" : "ブロック" }}</button>
                </p>
                <p class="controllerRow">選曲モード(自動選択):
                    <toggleSwitch v-model="isAutoAlgos" @change="autoAlgosChange" />
                </p>
                <p class="controllerRow">選曲モード: <select v-model="algosValue" @change="algosUpdate">
                        <option value="0">全期再生回数</option>
                        <option value="1">短期再生回数</option>
                        <option value="2">再生回数順</option>
                        <option value="4">完全再生回数順</option>
                        <option value="3">ランダム</option>
                    </select></p>
                <p class="controllerRow" v-if="videoInfo">ユーザモード: <select v-model="videoInfo.nicemusic.userMode"
                        @change="changeUserMode">
                        <option value="normal">通常</option>
                        <option value="equal">均等</option>
                    </select></p>
                <p class="controllerRow" v-if="videoInfo">おすすめモード:
                    <toggleSwitch v-model="videoInfo.nicemusic.recomend" @change="changeRecomend" />
                </p>
            </div>
            <div class="webContler" v-if="videoInfo">
                <!-- <div class="sideColor" :style="{backgroundColor: videoInfo.color}">&nbsp;</div> -->
                <div class="rightColumn" :style="{ backgroundColor: videoInfo.video.color }">
                    <!-- <div class="webControlerRow"><h2>Webコントローラー</h2></div> -->
                    <div class="webControlerRow" @click="openVideoPage">
                        <p><b>再生中の動画</b></p>
                        <p class="videoTitle"><a>{{ videoInfo.video.title }}</a></p>
                    </div>
                    <div class="webControlerRow flex space-between">
                        <p><b>再生者</b><br />{{ videoInfo.nicemusic.sender }}</p>
                        <p><b>再生時間</b><br />{{ videoInfo.video.length }}</p>
                        <p><b>動画再生回数</b><br />{{ subscFormat(videoInfo.video.viewCount) }}回</p>
                    </div>
                    <div class="authorRow" @click="openURL(videoInfo.author.url)">
                        <img v-if="videoInfo.author.thumbnail" :src="videoInfo.author.thumbnail" />
                        <p class="authorText">
                            {{ videoInfo.author.name }}<br />
                            チャンネル登録者: {{ subscFormat(videoInfo.author.subscript) }}人
                        </p>
                    </div>
                    <div class="videoThumb_ctl">
                        <img :src="videoInfo.video.thumbnail" />
                    </div>
                </div>


            </div>
            <div v-else>
                <p>動画情報が取得できませんでした。<br />動画をスキップすると表示される可能性があります。</p>
            </div>


        </div>
    </template>

    <template v-else>
        <div>
            <h2>Webコントローラー</h2>
            <p>コントローラーを使用するには、Nicemusicが有効なチャンネルに参加して開始してください。</p>
        </div>
    </template>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import io from 'socket.io-client'
import { subscFormat } from '@/utils/utils';
import toggleSwitch from '../items/toggleSwitch.vue';
const url = ref();
const store = useStore();
const socket = ref(null);
const gid = ref(null);
const vid = ref(null);
const videoInfo = ref(null);
const algosValue = ref(false);
const isAutoAlgos = ref(false);
const autoAlgosSearch = ref(false);
const favorite = ref();
const blocked = ref();

defineProps({
    userInfo: {}
})

const command = async (event, data) => {
    if (!socket.value || !gid.value || !event) return;

    await store.dispatch("Api/callApi", {
        url: `private/webConsoleCommand/${gid.value}/${event}`,
        data,
        loading: true
    })
    await getVideoInfo();
}

const changeUserMode = () => {
    command('userMode', {
        mode: videoInfo.value.nicemusic.userMode
    })
}

const changeRecomend = () => {
    command('recomend', {
        mode: videoInfo.value.nicemusic.recomend
    })
}

const parseUserValue = (userValue) => {
    // if(!userValue || userValue.algos === undefined || userValue.autoAlgos === undefined || userValue.autoAlgosSearch === undefined) throw new Error('アルゴリズムデータが不足しています')
    algosValue.value = userValue.algos;
    isAutoAlgos.value = userValue.autoAlgos ? true : false;
    autoAlgosSearch.value = userValue.autoAlgosSearch;
}

const algosUpdate = async () => {
    // console.log(algosValue.value);
    let userValue = await store.dispatch('Api/callApi', {
        url: "private/algosChange", data: { value: algosValue.value },
        loading: true
    });
    parseUserValue(userValue);
}

const load = async () => {
    url.value = store.getters["Api/targetURL"];
    socket.value = io(url.value, {
        withCredentials: true
    });
    // connectWebConsole
    socket.value.emit('webConsole/connection', null);

    // 接続後チェック
    socket.value.on('webConsole/check', (data) => {
        gid.value = data;
        // console.log("gid", gid.value)
    })

    socket.value.on('update/listening', () => {
        // console.log("reconnection");
        socket.value.emit('webConsole/connection', null);
    })

    getUserValue()
};

const getUserValue = async () => {
    const userValue = await store.dispatch('Api/callApi', {
        url: "private/algosSetting",
        loading: true
    });
    parseUserValue(userValue);
}

const getVideoInfo = async () => {
    if (!vid.value) return;
    videoInfo.value = await store.dispatch('Api/callApi', {
        url: `private/getVideoDetail/${vid.value}`,
        data: {
            gid: gid.value
        },
        loading: true
    });
    getVideoStatus();
    getUserValue()
}

const getVideoStatus = async () => {
    let st = await store.dispatch('Api/callApi', {
        url: "private/userMusicStatus", data: { url: vid.value },
        loading: true
    });
    favorite.value = st.favorite;
    blocked.value = st.blocked;
}

const toggleFav = async () => {
    let url = vid.value;

    await store.dispatch('Api/callApi', {
        url: "private/changeMusicStatus", data: { url, kind: "favorite", length: videoInfo.value.video.length_ },
        loading: true
    });
    getVideoStatus();
}

const toggleBlock = async () => {
    let url = vid.value;

    await store.dispatch('Api/callApi', {
        url: "private/changeMusicStatus", data: { url, kind: "block", length: videoInfo.value.video.length_ },
        loading: true
    });
    getVideoStatus();
}

const openVideoPage = async () => {
    await openURL("https://youtu.be/" + videoInfo.value.url)
}

const openURL = async (url) => {
    const cnf = await store.dispatch('Common/openConfirm', { header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}` });
    if (cnf) {
        let a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.click();
        a.remove();
    }
};

const autoAlgosChange = async () => {
    let userValue = await store.dispatch('Api/callApi', {
        url: "private/algoAutoChanger", data: { value: isAutoAlgos.value },
        loading: true
    });
    parseUserValue(userValue);
}

watch(gid, (v) => {
    // gidチェック
    if (!gid.value) return;
    socket.value.on('playing:' + v, async (data) => {
        vid.value = data;
        await getVideoInfo();
    })
})

onMounted(load);
onUnmounted(() => {
    console.log("disconnect");
    if (socket.value) socket.value.disconnect();
})
</script>

<style scoped>
.webContler {
    display: flex;
    width: 50%;
    min-height: 100px;
    overflow: hidden;
}

.sideColor {
    height: 100%;
    width: 5px;
    min-height: 100px;
}

.rightColumn {
    height: 100%;
    padding: 20px;
    width: 100%;
}

.webControlerRow {
    width: calc(100% - 60px);
    margin: 10px;
    padding: 20px;
    background-color: rgba(86, 86, 86, 0.8);
    border-radius: 20px;
}

.videoTitle {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.authorRow {
    display: flex;
    width: calc(100%);
    align-items: center;
    background-color: #333333;
    border-radius: 40px;
    overflow: hidden;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}

.authorRow img {
    width: 80px;
    border-radius: 40px;
    -webkit-user-drag: none;
}

.authorText {
    margin-left: 20px;
    font-weight: bold;
    text-decoration: underline;
    font-size: large;
}

.videoThumb_ctl {
    margin: 30px;
    width: calc(100% - 60px);
}

.videoThumb_ctl img {
    width: 100%;
}

.cntlBase {
    position: relative;
}

.controllerBody {
    position: fixed;
    min-width: 300px;
    background-color: #555555;
    right: 80px;
    padding: 20px;
    border-radius: 20px;
}

.controllerRow {
    display: flex;
    align-items: center;
    font-size: large;
    justify-content: space-between;
}

.controllerRow button {
    font-size: large;
}

.controllerRow select {
    font-size: large;
    width: 200px;
    text-align: center;
}
</style>