<template>
    <div class="iconTempBase"><img class="iconTempIcon" src="@/assets/Icon.png"/><span class="iconTempText wf-mplus1p">NiceMusic♪</span></div>
</template>
<style scoped>
.iconTempBase{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    user-select: none;
    z-index: 1;
}
.iconTempIcon{
    height: 64px;
    user-select: none;
    -webkit-user-drag: none;
}
.iconTempText{
    font-size: 64px;
    font-weight: bold;
}
</style>