<template>
    <template v-if="video">
        <tr>
            <td rowspan="2" :style="{ backgroundColor: v.domColor }"></td>
            <td rowspan="2" class="thumbnailsFav"><img :src="v.thumbnails" @click="openModal = true" /></td>
            <td rowspan="2" @click="openModal = true">{{ v.title }}</td>
            <td rowspan="2">{{ v.authorName }}</td>
            <td>{{ formatDate(v.insertAt, "YYYY年MM月DD日 HH:mm:ss", "日付データがありません") }}</td>
            <td rowspan="2"><button class="favoButton tableChangeBtn" :class="{ added: isRegist }" @click="editRegist">{{ isRegist ? "解除" : "登録" }}</button></td>
        </tr>
        <tr>
            <td>{{ formatDate(v.updateAt, "YYYY年MM月DD日 HH:mm:ss", "日付データがありません") }}</td>
        </tr>
    </template>

    <MusicModal v-if="openModal" :kind="'playlist'" :mytop="false" :music="v" @closeMusicModal="openModal = false" />
</template>

<script setup>
import MusicModal from '@/components/modals/musicModal.vue';
import { formatDate } from '@/utils/utils';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const props = defineProps({
    video: {},
    id: {type: Number, required: true}
})
const openModal = ref(false);

const v = computed(() => {
    return props.video;
})

// 登録中か
const isRegist = ref();

const load = async () => {
    isRegist.value = v.value.relId != null;
}

const editRegist = async () => {
    const resp = await store.dispatch('Api/callApi', {
        url: `private/playlist/${props.id}/regist`,
        data: {
            isRegist: !isRegist.value,
            url: v.value.url
        }
    });

    isRegist.value = resp;
}

load();
</script>