<template>
    <tr>
        <td class="thumbnailsFav" @click="modal = true"><img :src="vl.thumbnails"/></td>
        <td class="titleFav" @click="modal = true">{{vl.title}}</td>
        <td>{{vl.author.name}}</td>
        <td><button class="blockButton tableChangeBtn" :class="{added: status}" @click="toggleFav">ブロック{{status ? "解除" : ""}}</button></td>
    </tr>
    <musicModalVue v-if="vl && modal" :music="vl" @closeMusicModal="modal=false"></musicModalVue>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import musicModalVue from '@/components/modals/musicModal.vue';

export default {
    components: {
        musicModalVue
    },
    props: {
        vl: Object
    },
    setup(props){
        const store = useStore();
        const status = ref();
        const modal = ref(false);
        const load = async () => {
            let st = await store.dispatch('Api/callApi', {url: "private/userMusicStatus", data: {url: props.vl.url}});
            status.value = st.blocked;
        }
        load();
        const toggleFav = async () => {
            let url = props.vl.url;

            await store.dispatch('Api/callApi', {url: "private/changeMusicStatus", data: {url, kind: "block", length: props.vl.length}});
            await load();
        }
        return {
            status,
            toggleFav,
            modal
        }
    }
}
</script>