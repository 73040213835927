<template>
    <div class="videoMainBase">
        <h2>VideoLoggerトップページ</h2>
        <p>このページはVideoLoggerアクセス用ページです。</p>
        <br />
        <h2>対応ページ</h2>
        <ul>
            <a v-for="(site, index) in siteList" :key="index" target="_blank" :href="site[0]" :title="site[1]"><li>動画サイト{{ index+1 }}</li></a>
        </ul>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const siteList = ref([]);

        const load = async () => {
            siteList.value = await store.dispatch("Video/call", {url: "videos/siteLists"});
            // console.log(siteList.value);
        }
        load();

        return {
            siteList
        }
    }
}
</script>

<style scoped>

</style>