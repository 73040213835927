<template>
    <div class="privateContent">
        <div class="">
            <h2 class="w100 textCenter">ブロックリスト</h2>
            <p>現在ブロックされている動画が表示されます。</p>
            <br />
            <p>URLで動画を追加: <input type="text" placeholder="https://youtu.be/xxxx" v-model="url" /><a class="spBtn"
                    @click="regist">ブロック</a></p>
            <SpSearchArea v-model="setting" @change="load"></SpSearchArea>
            <PageNation v-model="current" :maxPage="maxPage" :keyObserb="true"></PageNation>
            <table class="w100 normal">
                <thead>
                    <tr>
                        <th>サムネイル</th>
                        <th>タイトル</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="viewList.length == 0">
                        <td colspan="3">ブロックリストがありません</td>
                    </tr>
                    <spBlkRow v-for="v in viewList" :key="v" :video="v"></spBlkRow>
                </tbody>
            </table>
            <PageNation v-model="current" :maxPage="maxPage"></PageNation>
        </div>
    </div>
</template>
<script>
import PageNation from '@/components/items/pageNation.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import SpSearchArea from '../item/spSearchArea.vue';
import spBlkRow from '../row/spBlkRow.vue'

export default {
    setup() {
        const store = useStore();
        const blocked = ref([]);
        const current = ref(1);
        const url = ref("");
        const setting = ref({
            count: "10"
        });
        const load = async () => {
            blocked.value = await store.dispatch('Api/callApi', { url: 'private/blocked' });
            // console.log(blocked.value);
        };
        load();

        const maxPage = computed(() => {
            return Math.ceil(blocked.value.length / setting.value.count);
        })

        const checkMax = (end) => {
            current.value = end
        }

        const viewList = computed(() => {
            let arry = [];
            let end = current.value * setting.value.count;
            let start = end - setting.value.count;
            // console.log(start, end);
            if (end < current.value) checkMax(end);
            arry = blocked.value.slice(start, end);
            return arry;
        });

        const regist = async () => {
            //inputChangeStatus
            let uu = url.value;
            url.value = "";
            let rslt = await store.dispatch('Api/callApi', { url: "private/inputChangeStatus", data: { url: uu, kind: "block" } });
            if (rslt) {
                await store.dispatch('Common/showModal', { text: "登録しました。" });
                load();
            } else {
                await store.dispatch('Common/showModal', { text: "登録に失敗しました。" });
            }

        }

        const openURL = async (url) => {
            const cnf = await store.dispatch('Common/openConfirm', { header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}` });
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
        }

        return {
            setting,
            load,
            viewList,
            regist,
            openURL,
            maxPage,
            current
        };
    },
    components: { SpSearchArea, PageNation, spBlkRow }
}
</script>