<template>
    <div class="registingFeald fadeIn">
        <!-- 登録エリア -->
        <div class="imageArea">
            <p><input ref="fileInput" type="file" @change="fileChange" placeholder="サムネイルを選択してください" /></p>
            <p v-if="!imageData">サムネイル画像を選択してください</p>
            <p v-if="updateContent != null">画像は更新するときのみ選択してください</p>
            <p><img class="previewImg" :src="imageData" /></p>
            <imageCropper v-if="isCropModal" :file="file" @close="isCropModal = false" @cancel="fileInput.value = null"
                @cropped="imageCanvas = $event" />
        </div>
        <div class="inputArea">
            <div class="inputAreaRow">
                <p class="label">タイトル:</p>
                <p class="value"><input v-model="inputData.title" placeholder="タイトル" /></p>
            </div>
            <!-- <div class="inputAreaRow">
                <p class="label">購読:</p>
                <p class="value">
                    <ToggleSwitch v-model="inputData.subscribe" />
                </p>
            </div> -->
            <div class="inputAreaRow">
                <p class="label">説明:</p>
                <p class="value"><textarea v-model="inputData.description" placeholder="説明"></textarea></p>
            </div>
            <div class="inputAreaRow">
                <p class="label"></p>
                <p class="value" v-if="updateContent == null"><a class="btn" :class="{ disabled: !registData.title }"
                        @click="regist">登録</a></p>
                <template v-else>
                    <p class="value"><a class="btn" :class="{ disabled: !registData.title }" @click="regist">更新</a></p>
                    <p class="value"><a class="btn" @click="close">キャンセル</a></p>
                </template>
            </div>
        </div>
    </div>
    <Teleport to="body">
        <template v-if="progress.max">
            <div class="bg-modal"></div>
            <div class="progress-div">
                <p>アップロード中</p>
                <p><progress :max="progress.max" :value="progress.value"></progress></p>
                <p>{{ Math.round(progress.value / progress.max * 100) }}% ( {{ progress.value }} / {{ progress.max }} )</p>
            </div>
        </template>
    </Teleport>
</template>

<script setup>
import imageCropper from '@/components/items/imageCropper.vue'
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const file = ref(null);
const fileInput = ref(null);
const isCropModal = ref(false);
const imageCanvas = ref(null);
const progress = ref({
    max: 0, value: 0
});

const props = defineProps({
    updateContent_: { default: null }
})

const updateContent = computed(() => {
    return props.updateContent_;
})

const emit = defineEmits(['change', 'close']);

const load = async () => {
    if (updateContent.value) {
        inputData.value = {
            title: updateContent.value.title,
            subscribe: updateContent.value.subscribe,
            description: updateContent.value.description,
            id: updateContent.value.id
        }
    }
}

const inputData = ref({
    title: "",
    subscribe: false,
    description: "",
});

const registData = computed(() => {
    const data = inputData.value;
    return data;
})


const regist = async () => {
    if (!registData.value.title) {
        await store.dispatch('Common/showModal', { text: "タイトルは必須です" });
        return;
    }
    const resp = await store.dispatch('Api/callApi', {
        url: "private/registplaylists", data: {
            data: registData.value
        }
    });

    if (resp) {
        inputData.value = {
            title: "",
            subscribe: true,
            description: "",
        }

        //imageRegist
        let uuid = null;
        if (imageData.value) {
            let registString = imageData.value.match(/.{1,1024}/g);
            for (let key in registString) {
                let rsp = await store.dispatch('Api/callApi', {
                    url: "private/registPlaylistImage",
                    loading: false,
                    data: {
                        data: {
                            chunk: registString[key],
                            uuid,
                            id: resp
                        }
                    }
                })
                uuid = rsp.uuid;
                progress.value = {
                    max: registString.length,
                    value: key
                }
            }

            try {
                await store.dispatch('Api/callApi', {
                    url: "private/registPlaylistImage",
                    data: {
                        data: {
                            chunk: null,
                            uuid,
                            id: resp
                        }
                    }
                })
                progress.value = {
                    max: registString.length,
                    value: registString.length
                }
            } catch (e) {
                throw new Error(e);
            } finally {
                progress.value = {
                    max: 0,
                    value: 0
                }
            }

        }

        file.value = null;
        fileInput.value.value = null;
        imageCanvas.value = null;

        await store.dispatch('Common/showModal', { text: "登録に成功しました" });
    } else {
        await store.dispatch('Common/showModal', { text: "登録に失敗しました" });
    }
    emit('change');
}

const close = () => {
    inputData.value = {
        title: "",
        subscribe: true,
        description: "",
    }
    emit('close');
}

const imageReg = /image\/.+/;
const maxSize = 5 * 1024 * 1024;
const fileChange = async (event) => {
    imageCanvas.value = null;
    file.value = (event.target.files || event.dataTransfer.files)[0] || null;
    const msgs = [];
    if (!imageReg.test(file.value.type)) msgs.push('画像ファイル以外を指定することはできません');
    if (maxSize < file.value.size) msgs.push(`指定できるファイルのサイズは5MBまでです`);

    if (msgs.length) {
        await store.dispatch('Common/showModal', { text: msgs.join('<br />') });
        file.value = null;
        fileInput.value.value = null;
        return;
    }
    if (file.value) isCropModal.value = true;
}

const imageData = computed(() => {
    if (!imageCanvas.value) return null;
    return imageCanvas.value.toDataURL('image/jpeg');
})

onMounted(load);
</script>

<style scoped>
.registingFeald {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 80px);
    margin: 20px;
    padding: 20px;
    background-color: #555555;
}

.imageArea {
    width: 30%;
}

.inputArea {
    width: 60%;
}

.inputArea .inputAreaRow {
    width: 100%;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputAreaRow input {
    width: 100%;
    font-size: 1em;
    height: 30%;
}

.inputAreaRow textarea {
    width: calc(100% - 1em);
    height: 100px;
    padding: 0.5em;
    resize: none;
}

p.label {
    width: 30%;
    height: 100%;
}

p.value {
    width: 70%;
}

.previewImg {
    width: 100%;
}

.progress-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 50%;
    text-align: center;
    color: white;
    background-color: #555555;
}

.progress-div progress {
    width: 100%;
}</style>