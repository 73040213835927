<template>
    <div class="spMenuBase" @click="closeMenu">
        <div class="MenuLists">
            <template v-if="MenuLists">
            <div v-for="m in MenuLists" :key="m" class="MenuRow" :class="{current: m.id==menuId}" @click="changeMenu(m.id)">
                <p>{{ m.name }}</p>
            </div>
            </template>
            <div class="MenuRow" @click="logout">
                <p>ログアウト</p>
            </div>
        </div>
    </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    props: {
        MenuLists: Object
    },
    setup(props, context){
        const store = useStore();
        const router = useRouter();
        const closeMenu = () => {
            context.emit('close');
        }
        const logout = async () => {
            await store.dispatch('Api/logout');
            router.push('/');
        }
        const menuId = computed(() => {
            return store.getters["Common/getMenu"];
        })

        const changeMenu = async (id) => {
            await store.dispatch('Common/setMenu', id);
        }
        return {
            closeMenu,
            logout,
            menuId,
            changeMenu
        }
    }
}
</script>

<style scoped>
.spMenuBase {
    position: absolute;
    top: 78px; left: 0; right: 0; bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    animation-name:fadeInAnime;
    animation-duration:1s;
    animation-fill-mode:forwards;
    opacity:0;
    z-index: 5;
}
.MenuLists{
    width: 60%; height: 100%;
    background-color: #3d3d3d;
    animation-name:fadeLeftAnime;
    animation-duration:0.5s;
    animation-fill-mode:forwards;
    opacity:0;
}
.MenuRow{
    width: 100%; height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: bold;
    border-bottom: #1e2124 2px solid;
    color: 	#8d8d8d;
    transition: 0.5s;
}
.MenuRow:hover,
.MenuRow.current {
    color: white;
}
</style>