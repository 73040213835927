<template>
    <div class="videoMainBase">
        <h2>お気に入り動画</h2>
        <p>お気に入り登録済み動画を表示します</p>
        <br />
        <h2>フィルタ</h2>
        <div class="searchField">
            <p>タイトル: <input type="text" v-model="title" placeholder="タイトル" @change="load" /></p>
            <p>ソートタイプ: <select v-model="sortType" @change="load">
                    <option value="0">登録日</option>
                    <option value="1">優先度</option>
                    <option value="2">再生順</option>
                </select><select v-model="sort" @change="load">
                    <option value="0">昇順</option>
                    <option value="1">降順</option>
                </select></p>
            <p>サイト検索: <select v-model="site" @change="load">
                    <option value="">すべて</option>
                    <option v-for="web in webList" :key="web" :value="web.value">{{ web.description }}</option>
                </select></p>
            <p>タグ検索: <select v-model="tag" @change="tagInput">
                    <option v-for="tag, index in tags" :key="index" :value="index"
                        :hidden="selectingTag.includes(index)">{{ tag }}</option>
                </select></p>
        </div>
        <Transition>
            <div v-if="selectingTag.length" class="tags">
                <div v-for="tag in selectingTag" :key="tag" class="tag" title="解除" @click="removeTag(tag)">&#x00d7; {{
                    tags[tag] }}</div>
            </div>
        </Transition>
        <PageNation v-model="current" :maxPage="maxView" :keyObserb="true" :viewMax="viewMax"></PageNation>
        <p>{{ videoCnt }}件登録中</p>
        <table class="normal w100">
            <thead>
                <tr>
                    <th>サムネイル</th>
                    <th>タイトル</th>
                    <th>サイト</th>
                    <th>優先度</th>
                    <th>最終再生日</th>
                    <th>登録日
                        <hr />更新日
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="videosLength == 0">
                    <td colspan="7">登録されている動画がありません<br />以下で探してください
                        <p v-for="site, index in siteList" :key="index"><a target="_blank" :href="site[0]"
                                :title="site[1]">動画サイト{{ index + 1 }}</a></p>
                    </td>
                </tr>
                <tr v-for="video of viewList" :key="video">
                    <td class="videoThumb"><a :href="video.url" target="_blank"><img :src="video.images" /></a></td>
                    <td><a :href="video.url" target="_blank">{{ video.title }}</a></td>
                    <td>{{ video.webName }}</td>
                    <td>{{ video.priority }}</td>
                    <td>{{ formatDate(video.lastPlayed) }}</td>
                    <td>{{ formatDate(video.insertAt) }}
                        <hr /> {{ formatDate(video.checked) }}
                    </td>
                    <td>
                        <p><a class="btn" :style="{ whiteSpace: 'nowrap' }" @click="modalValue = video">タグ登録</a></p>
                        <p><a class="btn" @click="check(video.url)">更新</a></p>
                        <p><a class="btn" @click="deleteVideo(video.url)">解除</a></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <PageNation v-model="current" :maxPage="maxView" :viewMax="viewMax"></PageNation>
    </div>
    <ModifyTag v-if="modalValue" :video="modalValue" @close="modalValue = null; load();" @load="load"></ModifyTag>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import PageNation from '../items/pageNation.vue';
import { formatDate } from '@/utils/utils'
import ModifyTag from './item/modifyTag.vue';

export default {
    setup() {
        const store = useStore();
        const videos = ref([]);
        const videoCnt = ref(0);
        const current = ref(1);
        const viewMax = ref(10);
        const tag = ref("");
        const modalValue = ref(null);

        const title = ref("");
        const site = ref("");
        const sort = ref("1");
        const sortType = ref("1");
        const tags = ref({});
        const selectingTag = ref([]);

        const webList = ref([]);

        const siteList = ref([]);
        const videosLength = computed(() => { return videos.value.length || 0; });

        const load = async () => {
            const resp = await store.dispatch("Video/call", {
                url: "videos/videoLists", data: {
                    title: title.value,
                    site: site.value,
                    sortType: sortType.value,
                    sort: sort.value,
                    tags: selectingTag.value
                }
            });
            // console.log(resp);
            videoCnt.value = resp.count;
            videos.value = resp.video;
            siteList.value = await store.dispatch("Video/call", { url: "videos/siteLists" });
            webList.value = await store.dispatch("Video/call", { url: "videos/getSiteLists" });
            tags.value = await store.dispatch('Video/call', { url: "videos/getTags" });
        };
        load();

        const maxView = computed(() => {
            return Math.ceil(videos.value.length / viewMax.value)
        });
        const viewList = computed(() => {
            let arry = [];
            let end = current.value * viewMax.value;
            let start = end - viewMax.value;
            arry = videos.value.slice(start, end);
            return arry;
        });

        const tagInput = () => {
            selectingTag.value.push(tag.value);
            tag.value = "";
            load();
        }

        const removeTag = (name) => {
            selectingTag.value = selectingTag.value.filter(v => v != name);
            load();
        }

        const deleteVideo = async (url) => {
            const cnf = await store.dispatch('Common/openConfirm', { header: "削除確認", text: `削除するともとには戻せません。よろしいですか？` });
            if (cnf) {
                await store.dispatch('Video/call', { url: "videos/deleteVideo", data: { url } });
                load();
            }
        }

        const check = async (url) => {
            //checkVideoResp
            const resp = await store.dispatch('Video/call', { url: "videos/checkVideoResp", data: { url } });

            if (resp) {
                await store.dispatch('Common/showModal', { text: "更新しました" });
            } else {
                await store.dispatch('Common/showModal', { text: "更新に失敗しました。<br />削除された可能性があります。" });
            }
            load();
        }

        return {
            videosLength,
            siteList,
            viewMax,
            current,
            maxView,
            title,
            viewList,
            site,
            sortType,
            sort,
            formatDate,
            load,
            webList,
            tags,
            tag,
            tagInput,
            selectingTag,
            removeTag,
            modalValue,
            deleteVideo,
            check,
            videoCnt
        };
    },
    components: { PageNation, ModifyTag }
}
</script>

<style scoped>
.searchField {
    width: calc(100% - 40px);
    background-color: gray;
    padding: 40px;
    border-radius: 20px;
    font-size: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.searchField input[type=text] {
    font-size: 32px;
    width: 300px;
    text-align: center;
}

.searchField select {
    font-size: 32px;
    text-align: center;
}

.tags {
    width: calc(100% - 40px);
    padding: 20px;
    margin-top: 20px;
    background-color: #36393e;
    text-align: center;
}

.tags .tag {
    display: inline-block;
    padding: 0.25em 0.5em;
    margin: 0.25em;
    border-radius: 0.25em;
    font-size: 20px;
    background-color: #5e5e5e;
    cursor: pointer;
    user-select: none;
}
</style>