<template>
    <tr>
        <td rowspan="2">{{ data.id }}</td>
        <td rowspan="2">
            <div v-if="userData" class="usersIconVue" :style="{width: `${50}px`, height: `${50}px`}" :title="userData.name">
                <img :src="userData.displayAvatarURL || userData.defaultAvatarURL" />
            </div>
        </td>
        <td rowspan="2">{{ data.name }}</td>

        <td rowspan="2">{{ data.registedAt ? "登録済" : "未登録" }}</td>

        <td rowspan="2">{{ data.subscribe ? "購読" : "未購読" }}</td>

        <th>公開設定</th>
        <td>{{ data.publishedFav ? "" : "非" }}公開</td>

        <th>登録曲数</th>
        <td>{{ data.favsCnt || 0 }}曲 / {{ data.allFavsCnt || 0 }}曲</td>

        <th>追記時</th>
        <td>{{ dateFormat(data.insertAt) }}</td>
        
        <th>最終更新日</th>
        <td>{{ dateFormat(data.updateAt) }}</td>
    </tr>
    <tr>
        <th>選曲設定</th>
        <td>{{ data.algos }}</td>

        <th>再生回数</th>
        <td>{{ data.current || 0 }}回 / {{ data.played || 0 }}回</td>

        <th>登録日</th>
        <td>{{ dateFormat(data.registedAt) }}</td>

        <th>最終再生日</th>
        <td>{{ dateFormat(data.lastPlayed) }}</td>
    </tr>
</template>
<script>
import moment from 'moment';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        data: Object
    },
    setup(props) {
        const store = useStore();
        const userData = ref();
        const load = async () => {
            if (!props.data)
                return;
            userData.value = await store.dispatch("Api/callApi", { url: "private/getUserDataAdmn", data: { id: props.data.id } });
            // console.log(userData.value);
        };
        load();

        const dateFormat = (date) => {
            let mom = moment(date);

            if(!mom.isValid()) return "なし";

            return mom.format('YYYY年MM月DD日 HH:mm:ss');
        }
        return {
            userData,
            dateFormat
        };
    },
}
</script>
<style scoped>
.usersIconVue {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    margin: 0.2em;
}
.usersIconVue:hover{
    transform: scale(110%);
    transition: 0.5s;
}
.usersIconVue img{
    width: 100%; height: 100%;
    user-select: none;
    -webkit-user-drag: none;
}
</style>