<template>
    <template v-if="!sleepMode">
        <headerView />
        <div class="privateBody">
            <div class="privateSide">
                <menuViewVue :menu="menu" :userInfo="userInfo"></menuViewVue>
                <div class="privateUserInfo" v-if="userInfo">
                    <img :src="userInfo.avatarURL || userInfo.defaultAvatarURL" />
                    <p>&nbsp;<b>{{`${userInfo.globalName || userInfo.username}`}}</b><br />さんようこそ</p>
                </div>
                <div class="bottomPrivateSide">&copy; Asogi Development, 2022<span v-if="year">&nbsp;-&nbsp;{{year}}</span></div>
            </div>
            <div class="privateContentArea">
                <component class="privateContent" v-bind:is="currentComponent" :userInfo="userInfo"></component>
            </div>
        </div>
    </template>
    <template v-else>
        <SleepView v-if="sleepMode" v-model="sleepMode"></SleepView>
    </template>
</template>
<script>
import { useStore } from 'vuex';
import iconTempVue from '@/components/items/iconTemp.vue';
import menuViewVue from '../components/menuView.vue'
import { onUnmounted, onMounted, ref, watch, computed } from 'vue';

import top from '@/assets/menu/top.png'
import favo from "@/assets/menu/favo.png"
import block from "@/assets/menu/block.png"
import setting from '@/assets/menu/setting.png'
import topFieldVue from '@/components/private/topField.vue';
import favoVue from '@/components/private/favoVue.vue'
import blkVueVue from '@/components/private/blkVue.vue';
import userSettingVue from '@/components/private/userSetting.vue';
import play from '@/assets/menu/play.png';
import adminRemoteVue from '@/components/private/adminRemote.vue';
import copyTokenVue from '@/components/private/copyToken.vue';
import logViewVue from '@/components/private/logView.vue';
import copy from "@/assets/menu/copy.png"
import usb from "@/assets/menu/usb.png"
import controller from "@/assets/menu/controller.png"
import webControllerVue from '@/components/private/webController.vue';
import musicListsVue from '@/components/private/musicLists.vue';
import music from "@/assets/menu/music.png";
import observeSetting from '@/components/private/observeSetting.vue';
import videoPng from '@/assets/menu/video.png'
import { useRouter } from 'vue-router';
import SleepView from '@/components/sleepView.vue';
import headerView from '@/components/headerView.vue'
import playListVue from '@/components/private/playList.vue'
import playlistPng from '@/assets/menu/playlist.png'
import messagePng from "@/assets/menu/message.png"
import notificationEditor from '@/components/private/notificationEditor.vue';
import analyzePage from '@/components/private/analyzePage.vue';
import analyze from '@/assets/menu/analyze.png'

export default {
    components: {
    iconTempVue,
    menuViewVue,
    topFieldVue,
    favoVue,
    blkVueVue,
    userSettingVue,
    adminRemoteVue,
    copyTokenVue,
    logViewVue,
    webControllerVue,
    musicListsVue,
    notificationEditor,
    observeSetting,
    SleepView,
    headerView,
    playListVue,
    analyzePage
},
    setup(){
        const store = useStore();
        const year = ref();
        const sleepMode = ref(false);
        // const sleepTime = ref(moment());
        const menuId = ref(0);
        const currentComponent = ref();
        const userInfo = ref();
        const router = useRouter();
        // const observeTime = ref();

        const menu = [
            {label: "TOP", image: top, component: "topFieldVue"},
            {label: "お気に入り", image: favo, component: "favoVue"},
            {label: "再生リスト", image: playlistPng, component: "playListVue"},
            {label: "ブロック", image: block, component: "blkVueVue"},
            {label: "解析", image: analyze, component: "analyzePage"},
            {label: "登録曲", image: music, component: "musicListsVue"},
            {label: "操作", image: controller, component: "webControllerVue"},
            {label: "トークン", image: copy, component: "copyTokenVue"},
            {label: "監視", image: videoPng, component: "observeSetting"},
            {label: "設定", image: setting, component: "userSettingVue"},
            {label: "遠隔実行", image: play, component: "adminRemoteVue", admin: true},
            {label: "ログ", image: usb, component: "logViewVue", admin: true},
            {label: "お知らせ投稿", image: messagePng, component: "notificationEditor", admin: true}
        ]

        const load = async () => {
            await store.dispatch('Api/callApi', {url: "private/"});
            let d = new Date();
            menuId.value = await store.getters["Common/getMenu"];
            currentComponent.value = menu[menuId.value].component;
            userInfo.value = await store.dispatch('Api/callApi', {url: "private/userInfo"});
            await store.dispatch("Common/isMember");

            // console.log(userInfo.value);
            calculateWindowWidth();

            if(2022 < d.getFullYear()){
                year.value = d.getFullYear();
            }
        }
        load();

        // const observeTimeMethod = () => {
        //     const LIMIT = 5;  //min
        //     const compDate = moment().subtract(LIMIT, "minutes");
            
        //     if(sleepTime.value.isBefore(compDate)) {
        //         sleepMode.value = true;
        //     }
        // }

        // const mousemove = () => {
        //     sleepTime.value = moment();
        // }

        const isMember = computed(() => {
            return store.getters["Common/isMember"];
        })

        const getMenuId = computed(() => {
            return store.getters["Common/getMenu"];
        })

        watch(getMenuId, (v) => {
            menuId.value = v;
            currentComponent.value = menu[v].component
            // console.log(currentComponent.value);
        })

        const calculateWindowWidth = () => {
            let width = window.innerWidth;
            
            // true/false
            if(width <= 480) {
                router.push("/sp");
            }
        }

        onUnmounted(() => {
            window.removeEventListener('resize', calculateWindowWidth);

            // if(process.env.NODE_ENV != 'development') {
            //     document.body.removeEventListener("mousemove", mousemove);
            //     if(observeTime.value) clearInterval(observeTime.value);
            // }
        })

        onMounted(() => {
            window.addEventListener('resize', calculateWindowWidth)

            // if(process.env.NODE_ENV != 'development') {
            //     //マウス移動イベント
            //     document.body.addEventListener("mousemove", mousemove);
            //     observeTime.value = setInterval(observeTimeMethod, 1000);
            // }
        })

        return {
            year,
            menuId,
            currentComponent,
            menu,
            userInfo,
            isMember,
            sleepMode
        }
    },
}
</script>
<style scoped>
@import url("../css/private.css");
</style>