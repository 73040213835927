<template>
    <div>
        <div class="logViewer">
            <div class="label">
                <div class="fileList">
                    <p>ファイル一覧 </p><img @click="getFiles" class="reloadBtn" src="../../assets/reload.png"/>
                </div>
                <div class="fileContent">
                    <p>内容 </p><img @click="getContent" class="reloadBtn" src="../../assets/reload.png"/>
                </div>
            </div>
            <div class="content">
                <div class="fileList">
                    <div class="fdir" v-for="(f, index) in files" :key="index">
                        <details>
                            <summary>{{index}}</summary>
                            <div class="fnameRow" :class="{selected: `${index}/${fname}` == selected}" v-for="fname in f" :key="fname" @click="selectRow(`${index}/${fname}`)">{{fname}}</div>
                        </details>
                    </div>
                </div>
                <div class="fileContent" id="fileContent" v-html="context"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
    setup(){
        const store = useStore();
        const files = ref({});
        const selected = ref("");
        const context = ref("");

        const fileContent = ref(null);

        const getFiles = async () => {
            files.value = await store.dispatch('Api/callApi', {url: "private/getLogFiles"});
            // console.log(files.value);
        }

        const getContent = async () => {
            if(!selected.value) return;
            context.value = await store.dispatch('Api/callApi', {url: "private/getLogContent", data: {fname: selected.value}});
        }

        const scrollContent = () => {
            if(fileContent.value) {
                
                fileContent.value.scrollTop = fileContent.value.scrollHeight;
                // console.log({
                //     scrollTop: fileContent.value.scrollTop,
                //     scrollHeight: fileContent.value.scrollHeight
                // })
            }
        }

        const selectRow = async (str) => {
            selected.value = str;
            await getContent();
        }

        function htmlspecialchars(str){
            return (str + '').replace(/&/g,'&amp;')
            .replace(/"/g,'&quot;')
            .replace(/'/g,'&#039;')
            .replace(/</g,'&lt;')
            .replace(/>/g,'&gt;'); 
        }

        const contextdata = computed(() => {
            let data = context.value;

            let escaped = htmlspecialchars(data).replace(/\t/g, '&#009;').replace(/ /g, '&nbsp;');

            let rsp = "";
            for(let row of escaped.split('\n')){
                let split = row.split("&nbsp;");

                //decorate
                if(1 < split.length){
                    split[0] = split[0].replace('[', `[<span class="logTimeText">`).replace(']', '</span>]');
                }

                // console.log(split);
                
                rsp += `<p>${split.join('&nbsp;')}</p>`;
            }

            rsp = rsp.replace(/\[TRACE\]/g, `[<span class="traceText">TRACE</span>]`)
            .replace(/\[DEBUG\]/g, `[<span class="debugText">DEBUG</span>]`)
            .replace(/\[INFO\]/g, `[<span class="infoText">INFO</span>]`)
            .replace(/\[WARN\]/g, `[<span class="warnText">WARN</span>]`)
            .replace(/\[ERROR\]/g, `[<span class="errorText">ERROR</span>]`)
            .replace(/\[FATAL\]/g, `[<span class="fatalText">FATAL</span>]`);
            
            return rsp.replace(/\r|\n/g, "");
        })

        watch(contextdata, () => {
            scrollContent();
        });

        const load = async () => {
            fileContent.value = document.getElementById('fileContent');
            await getFiles();
        }

        onMounted(load);
        

        return {
            files,
            getFiles,
            getContent,
            selected,
            selectRow,
            context: contextdata,
        }
    }
}
</script>