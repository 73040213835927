<template>
    <tr v-if="music" :class="{isPriority: priority}">
        <td :style="{backgroundColor: music.domColor, color: music.domColor}" @click="modal = true"></td>
        <td class="thumbnailsFav"><img :src="music.thumbnails" @click="modal = true" /></td>
        <td class="titleFav" @click="modal = true">{{music.title}}</td>
        <td class="authorNameFav" @click="authorClick">{{music.authorName || "取得中"}}</td>
        <td>{{music.current}}回/ {{music.played}}回(全期)</td>
        <td>{{ music.dev }}</td>
        <td>{{music.cnt}}人</td>
        <td>{{insertAt}}<hr />{{ updateAt }}</td>
        <td>{{lastPlayed}}</td>
        <td v-if="status"><button class="favoButton tableChangeBtn" :class="{added: status.favorite}" @click="toggleFav">登録{{status ? "解除" : ""}}</button></td>
        <td v-if="status"><button class="blockButton tableChangeBtn" :class="{added: status.blocked}" @click="toggleBlock">ブロック{{status ? "解除" : ""}}</button></td>
    </tr>
    <musicModalVue v-if="modalData && modal" :music="modalData" :kind="'allFavos'" @closeMusicModal="modal=false" @priorityUpdate="load"></musicModalVue>
</template>
<script>
import { ref, computed } from 'vue';
import store from '@/store';
import moment from 'moment';
import musicModalVue from '@/components/modals/musicModal.vue';
import { formatDate } from '@/utils/utils';

export default {
    props: {
        music: null
    },
    components: {
        musicModalVue
    },
    setup(props, context){
        const status = ref();
        const modal = ref(false);
        const priority = ref(false);
        const load = async () => {
            let st = await store.dispatch('Api/callApi', {url: "private/userMusicStatus", data: {url: props.music.url}});
            status.value = st;
            priority.value = st.priority;
        }
        load();
        const author = computed(() => {
            return JSON.parse(props.music.author);
        })

        const toggleFav = async () => {
            let url = props.music.url;

            await store.dispatch('Api/callApi', {url: "private/changeMusicStatus", data: {url, kind: "favorite", length: props.music.length}});
            await load();
        }
        const toggleBlock = async () => {
            let url = props.music.url;

            await store.dispatch('Api/callApi', {url: "private/changeMusicStatus", data: {url, kind: "block", length: props.music.length}});
            await load();
        }

        const insertAt = computed(() => {
            return moment(new Date(props.music.insertAt)).format('YYYY年MM月DD日 HH:mm:ss');
        })

        const updateAt = computed(() => {
            return formatDate(props.music.updateAt, undefined, "日付データがありません");
        })

        const lastPlayed = computed(() => {
            if(!props.music.lastPlayed) return "未再生";
            return moment(new Date(props.music.lastPlayed)).format('YYYY年MM月DD日 HH:mm:ss');
        })

        const modalData = computed(() => {
            let msc = props.music;

            if(!msc) return null;

            let rtn = null;

            try {
                rtn = JSON.parse(msc.author);
            } catch (error) {
                //エラー時の処理
                rtn = msc.author;
            }

            msc.author = rtn;
            return msc;
        })

        const authorClick = () => {
            if(props.music.authorName) {
                context.emit('author', props.music.authorName);
            }
        }

        return {
            author,
            insertAt,
            lastPlayed,
            status,
            toggleBlock,
            toggleFav,
            modal,
            modalData,
            priority,
            load,
            authorClick,
            updateAt
        }
    }
}
</script>