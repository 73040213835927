import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import Api from './modules/Api';
import Common from './modules/Common';
import Video from './modules/Video';
import Storage from './modules/Storage';
import Weather from './modules/Weather';
export default new createStore({
  modules: {
    Api,
    Common,
    Video,
    Storage,
    Weather
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})